import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";
import { Form, Button, Container, Row, Col, Card,Alert } from "react-bootstrap";
import { flashAction } from "../../store/customer/flashSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { validateOtp } from "../../utils/customer/validate";
import { signin } from "../../store/customer/authSlice";





const OtpVarification = ()=>{
    const [otp, setOtp] = useState("");
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
  const navigate = useNavigate();
  
    
    const location=useLocation();
    const emailToVarify=location.state?.email;

    const handleOtp=async(e)=>{
        e.preventDefault();
        setIsLoading(true);
        
        let formData = {};
        const validationError = validateOtp(otp);
    
        if (Object.keys(validationError).length > 0) {
          setError(validationError);
          return;
        } else {
          setError("");
            formData = { email: emailToVarify, enteredOtp: otp};
        }
    
        try{
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/verifyOtpAndRegister`,
            formData
          );
          const { token, message, userType, userId } = response.data;

          console.log(response.data);
          
          localStorage.setItem("token", token);
          dispatch(signin({ token, userId, email:emailToVarify, userType }));
          dispatch(flashAction.addFlash(message));
          navigate("/");
        }catch{
          console.error("Error registering:", error);
          dispatch(flashAction.addFlash(error.message));
          alert("Registration failed. Please try again.");
        }finally{
            setIsLoading(false);
        formData = {};
        }
      }

    return(
        <>
        <Container fluid className="d-flex justify-content-center my-5 vh-100">
           <Row className="justify-content-center w-100">
             <Col md={6} lg={4}>
               <Card className="p-4 shadow-lg rounded">
                 <Card.Title className="text-center mb-4">Varify Your Email</Card.Title>
                 <Form onSubmit={handleOtp} noValidate>
                 <Alert variant="success" className="text-center">
           <strong>OTP sent!</strong> Please check your inbox at <span style={{ color: '#007bff' }}>{emailToVarify}</span> for the verification code.
         </Alert>
                   <Form.Group controlId="otp">
                     <Form.Label>OTP</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Enter your email"
                       value={otp}
                       onChange={(e) => setOtp(e.target.value)}
                       required
                       autoComplete="off"
                     />
                     </Form.Group>
                     <Button
                         variant="primary"
                         type="submit"
                         className="mt-3 w-100"
                         disabled={isLoading}
                       >
                        {isLoading ? 'varifying....' : 'Varify OTP'}
                       </Button>
                     </Form>
                 </Card>
                 </Col>
                 </Row>
                 </Container>
       </>
    );
}


export default OtpVarification;