
import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import { useDispatch,  } from "react-redux";
import { flashAction } from "../../store/customer/flashSlice";
import { validateemail, validateForm, validatePassword } from "../../utils/customer/validate";
import { switchpage } from "../../utils/customer/switch";
import { signin } from "../../store/customer/authSlice";
import GoogleSign from "../../Components/customer/GoogleSignin";
import { GoogleOAuthProvider } from "@react-oauth/google";

const SingIn = ({ to }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let [page, setPage] = useState(to);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
   let validationError;
    if(page==="forget-pass"){
       validationError = validateemail(email);
    }else{ 
    validationError = validateForm(email,password);
    }
    let formData = {};
    if (Object.keys(validationError).length > 0) {
      setError(validationError);
      return;
    } else {
      setError("");
      setIsLoading(true);

      if (page === "signup" || page === "signin") {
        formData = { email: email, password: password };
      } else {
        formData = { email: email };
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/${page}`,
        formData
      );

      if(page==="signup"){
        if(response.status===200){
        navigate('/otpSent',{state:{email}});
        }else{
          console.log(response);
          console.log("Nhi Hua");
        }
      }else if(page==="forget-pass"){
        navigate('/reset-pass',{state:{email}})
      }else{

      const { token, message, userType, userId } = response.data;
      // setUser(response.data);
      dispatch(signin({ token, userId, userType }));
      dispatch(flashAction.addFlash({ message, type: 'success' }));

      navigate("/");
      }
    } catch (error) {
      console.error("Error registering:", error);
      dispatch(flashAction.addFlash({ message: error.message, type: 'error' }));
      alert("Registration failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  
  };




  return (
    <Container fluid className="d-flex flex-column justify-content-center align-items-center vh-85 bg-light py-4">
  <Row className="text-center mb-4">
    <Col>
      {page === "signin" ? (
        <h3 className="fw-bold display-6">Welcome Back</h3>
      ) : page === "signup" ? (
        <h3 className="fw-bold display-6">Sign Up</h3>
      ) : (
        <h3 className="fw-bold display-6">Reset Password</h3>
      )}
    </Col>
  </Row>

  <Row className="text-center mb-4">
    <Col>
      {page === "signin" ? (
        <p className="text-muted">
          Don't have an account?{" "}
          <Link
            onClick={() =>
              switchpage("signup", navigate, setPage, setEmail, setPassword, setError)
            }
            className="text-decoration-none"
          >
            Register here
          </Link>
        </p>
      ) : (
        <p className="text-muted">
          Already have an account?{" "}
          <Link
            onClick={() =>
              switchpage("signin", navigate, setPage, setEmail, setPassword, setError)
            }
            className="text-decoration-none"
          >
            Sign in here
          </Link>
        </p>
      )}
    </Col>
  </Row>

  <Row className="justify-content-center w-100">
    <Col xs={11} sm={8} md={6} lg={4}>
      <Card className="shadow-lg rounded-4">
        <Card.Body className="p-4">
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label className="fw-semibold">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
                className="py-2"
              />
              {error.email && (
                <span className="text-danger small">{error.email}</span>
              )}
            </Form.Group>

            {page === "signin" || page === "signup" ? (
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label className="fw-semibold">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="off"
                  className="py-2"
                /> 
                {(password!=="" && !validatePassword(password))&& <Form.Text className="text-muted small">
                Must be 6+ chars, include upper, lower, number & special character.
              </Form.Text>}
                {error.password && (
                  <span className="text-danger small">{error.password}</span>
                )}
              </Form.Group>
            ) : null}

            {page === "signin" && (
              <div className="d-flex justify-content-between mb-3">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  className="text-muted"
                />
                <Link
                  onClick={() =>
                    switchpage("forget-pass", navigate, setPage, setEmail, setPassword, setError)
                  }
                  className="text-decoration-none small"
                >
                  Forgot Password?
                </Link>
                
              </div>
            )}

            <Button
              variant="primary"
              type="submit"
              className="w-100 py-2"
              disabled={isLoading}
            >
              {isLoading
                ? page === "signup"
                  ? "Sending OTP..."
                  : "Signing In..."
                : page === "signin"
                ? "Sign In"
                : page === "signup"
                ? "Sign Up"
                : "Reset Password"}
            </Button>

            {(page === "signin" || page === "signup") && (
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGIN_ID}>
             <GoogleSign/>
             </GoogleOAuthProvider>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>

  );


};

export default SingIn;
