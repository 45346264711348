import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignin: localStorage.getItem("token"),
  token: localStorage.getItem("token") || null,
  userType: localStorage.getItem("userType") || null,
  userId:localStorage.getItem("userId") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin: (state, action) => {
      
      const { token, userId, userType } = action.payload;
      state.token = token;
      state.userId = userId;
      state.userType = userType;
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userType", userType);
      state.isSignin = true;
    },
    signout: (state) => {
      state.isSignin = false;
      state.token = null;
      state.userId = null;
      state.userType = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userType");
    },
  },
});

export const { signin, signout } = authSlice.actions;
export default authSlice;
