import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchInfluencers = createAsyncThunk(
  "influencers/fetchInfluencers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/influencer/get`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchInfluencerById = createAsyncThunk(
  "influencers/fetchInfluencerById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/influencer/get/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  influencers: [],
  influencerById: null,
  isLoading: false,
  error: null,
};

const influencerSlice = createSlice({
  name: "influencers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.influencers = action.payload;
      })
      .addCase(fetchInfluencers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchInfluencerById.fulfilled, (state, action) => {
        state.influencerById = action.payload;
      });
  },
});

export const selectInfluncer = (state) => state.influencers;
export const selectSingleInfluncer = (state) =>
  state.influencers.influencerById;

export default influencerSlice.reducer;
