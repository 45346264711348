import React from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { incrementAdClicks } from '../../../store/AdsSlice';

function CodeAd({ ad }) {

    const dispatch = useDispatch();
    const handleAdClick = () => {
      dispatch(incrementAdClicks(ad._id));
    };

  return (
    
  <Card className="mb-4">
    <Card.Body>
      <Card.Title>Code Ad</Card.Title>
      <pre>{ad.code}</pre>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">{ad.bannerSize}</small>
    </Card.Footer>
  </Card>


  )
}

export default CodeAd