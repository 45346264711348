import React, { useState } from 'react';
import 'react-bootstrap';
import LowerNavbar from './LowerNavbar';
import UperNavBar from './UpperNavbar';

// import '../../App.css'
const NavBar=({styles})=>{


    const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen); 
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };



   
    return (

      <>
      <UperNavBar data={{isOpen, handleToggle}} styles={styles}/>
      <LowerNavbar data={{isOpen, handleToggle, handleLinkClick}}/>
      </>
    
   
    
    );
}

export default NavBar;
