import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Spinner, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import "react-bootstrap";

const UperNavBar = ({ styles, data }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({ coins: [], exchanges: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const {isOpen, handleToggle}=data;


 
  const navRef = useRef(null);

  const handleLinkClick = () => {
    setIsChecked(false); // Menu close on link click
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current) {
  
        if (!navRef.current.contains(event.target)) {
          console.log('Outside click detected');
          setIsChecked(false);
        } else {
          console.log('Clicked inside the nav');
        }
      } else {
        console.log('navRef is null');
      }
    };
  
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick); // Mobile support
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);

  


  // Trigger search when query changes
  useEffect(() => {
    if (!query) {
      setResults({ coins: [], exchanges: [] });
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/search?query=${query}`
        );
        setResults(response.data);
      } catch (err) {
        setError("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchData, 500);
    return () => clearTimeout(timeoutId);
  }, [query]);

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Container fluid className={`${styles.headerNavbar} py-3 shadow-sm`}>
      <Row className="align-items-center mx-lg-2">
        {/* Logo Section */}
        <Col xs={12} className="d-block d-lg-none">
          <nav ref={navRef} className={styles.navbar}>
            <div className={`${styles.navbarContainer} ${styles.container}`}>
              <input
                type="checkbox"
                id="menu-toggle"
                className={styles.checkbox}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className={styles.hamburgerLines}>
                <span className={`${styles.line} ${styles.line1}`}></span>
                <span className={`${styles.line} ${styles.line2}`}></span>
                <span className={`${styles.line} ${styles.line3}`}></span>
              </div>
              <ul
                className={`${styles.menuItems} ${
                  isChecked ? styles.menuOpen : ""
                }`}
              >
                <li>
                <Nav.Link
                className="custom"
                as={Link}
                to="/"
                onClick={handleLinkClick}
              >
                Home
              </Nav.Link>
                </li>
                <li>
                <Nav.Link
                className="custom"
                as={Link}
                to="/market-news"
                onClick={handleLinkClick}
              >
                Market News
              </Nav.Link>
                </li>
                <li>
                <NavDropdown
                className="nav-dropdown-custom"
                title="Research"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="market-analysis"
                  onClick={handleLinkClick}
                >
                  Market Analysis
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="market-reports"
                  onClick={handleLinkClick}
                >
                  Market Reports
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="articles"
                  onClick={handleLinkClick}
                >
                  Articles
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to="analytics"
                  onClick={handleLinkClick}
                >
                  Analytics
                </NavDropdown.Item>
              </NavDropdown>
                </li>
                <li>
                <NavDropdown className="nav-dropdown-custom" title="Top 100">
                <NavDropdown.Item
                  as={Link}
                  to="top100"
                  onClick={handleLinkClick}
                >
                  Top 100 Cryptocurrency
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/top-100-exchange"
                  onClick={handleLinkClick}
                >
                  Top 100 Exchange
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" onClick={handleLinkClick}>
                  Top 100 People
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" onClick={handleLinkClick}>
                  Top 100 Businesses
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/top-100-influncer"
                  onClick={handleLinkClick}
                >
                  Top 100 Influencer
                </NavDropdown.Item>
              </NavDropdown>
                </li>
                <li>
                <Nav.Link className="custom" as={Link} to="#link3">
                Free Crypto
              </Nav.Link>
                </li>
                <li>
                <NavDropdown
                className="nav-dropdown-custom"
                title="Crypto Projects"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/newListings"
                  onClick={handleLinkClick}
                >
                  New Listings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/upcoming-projects"
                  onClick={handleLinkClick}
                >
                  Upcoming Projects
                </NavDropdown.Item>
              </NavDropdown>
                </li>
                <li>
                  
              <Nav.Link
                className="custom"
                as={Link}
                to="#"
                onClick={handleLinkClick}
              >
                Events
              </Nav.Link>
                </li>
              </ul>
              <Link to="/" className={styles.upperLogo}>
            <img
              src={process.env.REACT_APP_NAV_LOGO}
              alt="Logo"
              className={styles.logo}
            />
          </Link>
            </div>
          </nav>
        </Col>
        <Col xs={6} md={4} className=" d-none d-lg-block text-center text-md-start mb-3 mb-md-0">
          <Link to="/">
            <img
              src={process.env.REACT_APP_NAV_LOGO}
              alt="Logo"
              className={styles.logo}
            />
          </Link>
        </Col>

        {/* Spacer Column for larger screens */}
        <Col lg={4} className="d-none d-lg-block"></Col>

        {/* Search Form Section */}
        <Col
          xs={12}
          md={4}
          className={`${styles.searchContainer} d-flex justify-content-center position-relative`}
        >
          <Form className={`${styles.searchForm} d-flex align-items-center`}>
            <div className={styles.searchIcon}>
              <BsSearch size={20} />
            </div>
            <Form.Control
              type="text"
              value={query}
              onChange={handleSearchChange}
              placeholder="Enter keyword..."
              className={`${styles.searchInput} ps-5`} // Add padding to make space for the icon
            />
            <div className={styles.secondsearchIcon}>/</div>
          </Form>

          {/* Search Results Dropdown */}
          {query && (
            <div className={styles.searchResultsDropdown}>
              {loading ? (
                <div className="text-center py-2">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : error ? (
                <div className="text-danger text-center py-2">{error}</div>
              ) : (
                <>
                  {results.coins.length > 0 && (
                    <div className="px-3 py-2">
                      <div className={styles.resultType}>
                        Coins
                        <div className={styles.underline}></div>
                      </div>
                      {results.coins.slice(0, 5).map((coin) => (
                        <div
                          key={coin.id}
                          className="d-flex justify-content-between"
                        >
                          <div className={styles.resultItem}>
                            <Link
                              to={`/coin-profile/${coin.id}`}
                              onClick={() => setQuery("")}
                              className="text-decoration-none text-reset"
                            >
                              <img src={coin.img.url} alt="" width={14} />{" "}
                              {coin.name} {coin.symbol}
                            </Link>
                          </div>
                          <div className={styles.alignRight}>
                            ${coin.quotes.USD.price.toFixed(3)}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {results.exchanges.length > 0 && (
                    <div className="px-3 py-2">
                      <div className={styles.resultType}>
                        Exchange
                        <div className={styles.underline}></div>
                      </div>
                      {results.exchanges.slice(0, 5).map((exchange) => (
                        <div key={exchange.cpid} className={styles.resultItem}>
                          <Link
                            to={`/exchange-profile/${exchange.cpid}`}
                            onClick={() => setQuery("")}
                            className="text-decoration-none text-reset"
                          >
                            #{exchange.adjusted_rank} {exchange.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}

                  {results.coins.length === 0 &&
                    results.exchanges.length === 0 &&
                    !loading && (
                      <></>
                    )}
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UperNavBar;
