import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./css/TopInfluencer.module.css";
import { Link } from "react-router-dom";
import {
  fetchInfluencers,
  selectInfluncer,
} from "../../store/customer/influncerSlice";

import {
  FaFacebook,
  FaTwitter,
  FaSkype,
  FaGoogle,
  FaPlus,
} from "react-icons/fa";

const TopInfluencer = () => {
  const dispatch = useDispatch();
  const { influencers, isLoading } = useSelector(selectInfluncer);

  useEffect(() => {
    dispatch(fetchInfluencers());
  }, [dispatch]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <section className={styles.teamContainer} id="team">
      <div className="container px-3">
        <h5 className={styles.sectionTitle}>OUR TOP Influencers</h5>
        <div className="row">
          {influencers.map((influencer) => (
            <div className="col-xs-12 col-sm-6 col-md-4" key={influencer._id}>
              <Link
                to={`/top-100-influncer/${influencer._id}`}
                className={styles.imageFlip}
              >
                <div className={styles.frontside}>
                  <div className={styles.card}>
                    <div className={`${styles.cardBody} text-center`}>
                      <p>
                        <img
                          className={`${styles.cardImg} img-fluid`}
                          src={influencer?.image?.url}
                          alt="img"
                        />
                      </p>
                      <h4 className={`${styles.cardTitle} card-title`}>
                        {influencer.name}
                      </h4>
                      <div className={styles.infoAboutinfluencer}>
                      <span className="card-text"><span>Category:</span> {influencer.category}</span>
                      <span className="card-text"><span>Gender:</span> {influencer.gender}</span>
                      <span className="card-text"><span>Rank:</span> {influencer.rank}</span>
                      <span className="card-text"><span>Age:</span> {influencer.age}</span>
                      <span className="card-text"><span>City:</span> {influencer.city}</span>
                      </div>
                      <a
                        href={influencer.profileUrl || "#"}
                        className={`${styles.btnPrimary} btn btn-sm`}
                      >
                        <FaPlus style={{ fontSize: "20px", color: "#fff" }} />
                      </a>
                    </div>
                  </div>
                </div>

                <div className={styles.backside}>
                  <div className={styles.card}>
                    <div className={`${styles.cardBody} text-center mt-4`}>
                      <h4 className={styles.cardTitle}>{influencer.name}</h4>
                      <p className="card-text">
                        {influencer.about ||
                          "More details about the influencer."}
                      </p>

                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <FaFacebook size={24} color="#108d6f" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <FaTwitter size={24} color="#108d6f" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <FaSkype size={24} color="#108d6f" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <FaGoogle size={24} color="#108d6f" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopInfluencer;
