import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import {
  fetchCoins,
  listenForSocketUpdates,
  selectCoinList,
  selectcoinStatus,
} from "../../store/customer/coinsSlice";
import { formatNumber } from "../../utils/common/formateNumber";
import FakeUpdation from "../shimmers/FakeUpdation";
import personalStyle from "./css/ListingDetails.module.css";
import { Link } from "react-router-dom";

const CoinItems = memo(({ item }) => {
  const renderPercentageChange = (value) => {
    if (value > 0) {
      return (
        <span className="text-success d-flex align-items-center">
          <FaCaretUp className="me-1" /> {value.toFixed(2)}%
        </span>
      );
    } else {
      return (
        <span className="text-danger d-flex align-items-center">
          <FaCaretDown className="me-1" /> {Math.abs(value).toFixed(2)}%
        </span>
      );
    }
  };

  return (
    <Row className={`${personalStyle.listRow} text-start py-2 border-top border-bottom justify-content-center align-items-start`}>
      <Col className="d-none d-md-block" md={1}>
        {item.rank}
      </Col>

      <Col xs={3} md={2}>
        <Link
          to={`/coin-profile/${item.id}`}
          className="text-decoration-none text-body"
        >
          <img src={item?.img?.url} alt="" width={24} className="me-1" />
          {item?.symbol}
        </Link>
      </Col>

      <Col xs={3} md={2}>
       <FakeUpdation price={item?.quotes.USD.price}/>
      </Col>
      <Col xs={3} md={1}>
        {renderPercentageChange(item?.quotes.USD.percent_change_1h)}
      </Col>
      <Col xs={3} md={1}>
        {renderPercentageChange(item?.quotes.USD.percent_change_24h)}
      </Col>
      <Col className="d-none d-md-block" md={1}>
        {renderPercentageChange(item?.quotes.USD.percent_change_7d)}
      </Col>
      <Col className="d-none d-md-block" md={2}>
        ${formatNumber(item?.quotes.USD.volume_24h)}
      </Col>
      <Col className="d-none d-md-block" md={2}>
        ${formatNumber(item?.quotes.USD.market_cap)}
      </Col>
    </Row>
  );
});

function ListingDetails({ styles = personalStyle, count = 100 }) {
  const dispatch = useDispatch();
  const coinData = useSelector(selectCoinList);
  const coinStatus = useSelector(selectcoinStatus);
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(listenForSocketUpdates());
    if (coinStatus === "idle") {
      dispatch(fetchCoins());
    }

    if (coinStatus === "succeeded") {
      setData(coinData.slice(0, count));
    }

    const intervalId = setInterval(() => {
      if (coinStatus === "succeeded") {
        dispatch(fetchCoins());
      }
    }, 200000);

    return () => clearInterval(intervalId);
  }, [coinStatus, coinData, dispatch]);

  const handleSort = (key) => {
    const order = sortBy === key && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(key);
    setSortOrder(order);

    const sortedData = [...data].sort((a, b) => {
      const aValue = key === "rank" ? a[key] : a.quotes.USD[key];
      const bValue = key === "rank" ? b[key] : b.quotes.USD[key];

      if (order === "asc") {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });

    setData(sortedData);
  };

  if (coinStatus === "failed") {
    return <div>Error loading coin data</div>;
  }

  return (
    <Container fluid className={styles?.coinTableText}>
      <Row className="text-start fw-bold border-top border-bottom py-3 justify-content-center align-items-start">
        <Col
          md={1}
          className="d-none d-md-block"
          onClick={() => handleSort("rank")}
          style={{ cursor: "pointer" }}
        >
          # {sortBy === "rank" && (sortOrder === "asc" )? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col xs={3} md={2} className="fs-9 fs-md-5">
          Coin
        </Col>
        <Col
          xs={3}
          md={2}
          className="fs-9 fs-md-5"
          onClick={() => handleSort("price")}
          style={{ cursor: "pointer" }}
        >
          Price {sortBy === "price" && (sortOrder === "asc") ? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col
          xs={3}
          md={1}
          className="fs-9 fs-md-5"
          onClick={() => handleSort("percent_change_1h")}
          style={{ cursor: "pointer" }}
        >
          1h {sortBy === "percent_change_1h" && (sortOrder === "asc") ? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col
          xs={3}
          md={1}
          className="fs-9 fs-md-5"
          onClick={() => handleSort("percent_change_24h")}
          style={{ cursor: "pointer" }}
        >
          24h {sortBy === "percent_change_24h" && (sortOrder === "asc") ? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col
          md={1}
          className="d-none d-md-block fs-md-5"
          onClick={() => handleSort("percent_change_7d")}
          style={{ cursor: "pointer" }}
        >
          7d {sortBy === "percent_change_7d" && (sortOrder === "asc" )? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col
          className="d-none d-md-block fs-md-5"
          md={2}
          onClick={() => handleSort("volume_24h")}
          style={{ cursor: "pointer" }}
        >
          24h Volume {sortBy === "volume_24h" && (sortOrder === "asc") ? <FaCaretUp /> : <FaCaretDown />}
        </Col>
        <Col
          className="d-none d-md-block fs-md-5"
          md={2}
          onClick={() => handleSort("market_cap")}
          style={{ cursor: "pointer" }}
        >
          Market Cap {sortBy === "market_cap" && (sortOrder === "asc") ? <FaCaretUp /> : <FaCaretDown />}
        </Col>
      </Row>

      {data?.slice(0, count).map((item) => (
        <CoinItems key={item.rank} item={item} />
      ))}
    </Container>
  );
}

export default ListingDetails;
