import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import homeStyle from "./css/Home.module.css";
import Tickers from "../../Components/customer/Tickers";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpcomingCryptos } from "../../store/upcommingCryptoSlice";

const CoinItems = ({ item, rank }) => {
  return (
    <Row
      className={`text-start py-2 border-top border-bottom justify-content-start align-items-start mx-3 ${homeStyle.exchanges}`}
    >
      <Col xs={1}>{rank}</Col>
      <Col xs={3}>
        <img src={item?.icon?.url} alt="" width={24} className="me-1" />
        {item?.name}
      </Col>
      <Col xs={4}>{item.symbol}</Col>
      <Col xs={4}>
        <Link
          to="#"
          onClick={() =>
            window.open(item.website, "_blank", "noopener,noreferrer")
          }
        >
          Website
        </Link>
      </Col>
      {/* <Col sm={2}></Col> */}
    </Row>
  );
};

function UpcomingProjects({ count = 20 }) {
  const dispatch = useDispatch();
  const upcomingData = useSelector((state) => state.upcommingCrypto.list);
  const upcomingStatus = useSelector((state) => state.upcommingCrypto.status);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (upcomingStatus === "idle") {
      dispatch(fetchUpcomingCryptos());
    }

    if (upcomingStatus === "succeeded") {
      setData(upcomingData);
    }
  }, [upcomingStatus, upcomingData, dispatch]);

  return (
    <Container fluid className={`${homeStyle?.coinTableText} `}>
      <Tickers styles={homeStyle} />
      <div className={`${homeStyle.heading} mx-3 mt-3 mb-5`}>
        Best Upcoming Cryptocurrencies
      </div>

      <Row
        className={`text-start fw-bold border-top border-bottom py-3 justify-content-center align-items-start mx-3 ${homeStyle.exchanges}`}
      >
        <Col xs={1}>Rank</Col>
        <Col xs={3}>Currencies</Col>
        <Col xs={4}>Symbol</Col>
        <Col xs={4}>Websites</Col>
        {/* <Col>Currencies</Col>
        <Col>Confidence Score</Col> */}
      </Row>

      {data?.slice(0, count).map((item, index) => (
        <CoinItems key={item.id} item={item} rank={index + 1} />
      ))}
    </Container>
  );
}

export default UpcomingProjects;
