import { createSlice } from "@reduxjs/toolkit";

const adminAuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      const { username, password } = action.payload;
      state.username = username;
      state.password = password;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.username = null;
      state.password = null;
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("userType")
    },
  },
});

export const selectCheckAuthentication = (state) => state.adminAuth.isAuthenticated;
export const { login, logout } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
