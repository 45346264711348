import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNews = createAsyncThunk("news/admin/fetchNews", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/news/get`
  );
  return response.data.news;
});

const adminNewsSlice = createSlice({
  name: "news",
  initialState: {
    list: [],
    filterNews: "all",
    status: "idle",
  },
  reducers: {
    setFilterNews: (state, action) => {
      state.filterNews = action.payload;
    },
    deleteNewsItem: (state, action) => {
      const newsItem = state.list.find((news) => news._id === action.payload);
      if (newsItem) {
        newsItem.deleted = true;
      }
    },

    updateNewsStatus: (state, action) => {
      const { id, status } = action.payload;
      const newsItem = state.list.find((news) => news._id === id);
      if (newsItem) {
        newsItem.status = status;
      }
    },
    updateNewsVisibility: (state, action) => {
      const { id, visibility } = action.payload;
      const newsItem = state.list.find((news) => news._id === id);
      if (newsItem) {
        newsItem.contentFor = visibility;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload?.map((news) => ({
          ...news,
          deleted: false,
        }));
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setFilterNews,
  deleteNewsItem,
  updateNewsStatus,
  updateNewsVisibility,
} = adminNewsSlice.actions;

export default adminNewsSlice.reducer;
