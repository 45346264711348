import React from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { incrementAdClicks } from "../../../store/AdsSlice";

function ResponsiveAd({ ad }) {
  const dispatch = useDispatch();
  const handleAdClick = () => {
    dispatch(incrementAdClicks(ad._id));
  };
  return (
    <Card className="mb-4">
      <Card.Img variant="top" src={ad.img?.url} alt={ad.img?.fileName} />
      <Card.Body>
        <Card.Title>{ad.header}</Card.Title>
        <Card.Text>{ad.AdText}</Card.Text>
        <Card.Text>Created by: {ad.name}</Card.Text>
        <Button href={ad.buttonUrl} variant="primary" onClick={handleAdClick}>
          {ad.button}
        </Button>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">{ad.bannerSize}</small>
      </Card.Footer>
    </Card>
  );
}

export default ResponsiveAd;
