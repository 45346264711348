import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import {
  listenForSocketUpdates,
} from "../../store/customer/coinsSlice";
import styles from './css/Home.module.css';
import { fetchExchange, selectExchangeList, selectExchangeStatus } from "../../store/customer/exchnageSlice";
import Tickers from "../../Components/customer/Tickers";
import { formatNumber } from "../../utils/common/formateNumber";
import { Link } from "react-router-dom";

const CoinItems = memo(({ item }) => {

  

  return (
    <Row className={`text-start py-2 border-top border-bottom justify-content-start align-items-start mx-3 ${styles.exchanges}`}>
      <Col md={1} className="d-none d-md-block">{item.adjusted_rank}</Col>
      <Col xs={3} md={2} >
      <Link to={`/exchange-profile/${item.cpid}`} className="text-decoration-none text-body">
        <img src={item?.img?.url} alt="" width={18} className="me-1" />
        {item?.name}</Link>
      </Col>
      {/* <Col xs={3} md={2}><FakeUpdation price={item?.quotes.USD.price}/></Col> */}
      <Col xs={2}>
        {formatNumber(item?.quotes.USD.reported_volume_24h.toFixed(2))}
      </Col>
      <Col xs={2}>
        {item?.markets}
      </Col>
      <Col xs={2}>
        {item?.currencies}
      </Col>
      <Col xs={3}>
      <ProgressBar 
        now={(item?.confidence_score *100).toFixed(2)} 
        label={`${(item?.confidence_score *100).toFixed(2)}%`} 
        variant="secondary" 
        style={{ height: '1.2rem' }} 
      />
      </Col>
     
      {/* 
      <Col className="d-none d-md-block" md={2}>
        ${formatNumber(item?.quotes.USD.market_cap)}
      </Col> */}
    </Row>
  );
});

function TopExchange({count=100 }) {
  const dispatch = useDispatch();
  const exchangeData = useSelector(selectExchangeList);
  const exchangeStatus = useSelector(selectExchangeStatus);
  const [data, setData] = useState([]);
  

  useEffect(() => {
    dispatch(listenForSocketUpdates()); 
    if (exchangeStatus === "idle") {
      dispatch(fetchExchange());
    }

    if (exchangeStatus === "succeeded") {
      setData(exchangeData);
    }
    
    const intervalId = setInterval(() => {
      if (exchangeStatus === "succeeded") {
        dispatch(fetchExchange());
      }
    }, 200000);

    return () => clearInterval(intervalId);
  }, [exchangeStatus, exchangeData, dispatch]);



  if (exchangeStatus === "failed") {
    return <div>Error loading coin data</div>;
  }

  return (
    <Container fluid className={`${styles?.coinTableText} `}>
      <Tickers styles={styles}/>
      <div className={`${styles.heading} mx-3 mt-3 mb-5`}>Top 100 Cryptocurrency Spot Exchanges</div>
      
      <Row className={`text-start fw-bold border-top border-bottom py-3 justify-content-center align-items-start mx-3 ${styles.exchanges}`}>
        <Col md={1} className="d-none d-md-block" >Rank</Col> 
        <Col xs={3} md={2} >Exchange</Col>
        <Col xs={2}>Volume</Col>
        <Col xs={2}>Markets</Col>
        <Col xs={2}>Currencies</Col>
        <Col xs={3}>Confidence <span className="d-none d-sm-block"> Score</span></Col>
      </Row>

      {data?.slice(0, count).map((item) => (
        <CoinItems key={item.rank} item={item} />
      ))}
    </Container>
  );
}

export default TopExchange;
