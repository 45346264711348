import React, { useState, useEffect } from "react";
import styles from "./css/ExchangeProfile.module.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { IoIosLink, IoLogoTwitter } from "react-icons/io";
import GaugeChart from "react-gauge-chart";
import axios from "axios";
import Shimmer from "../../Components/shimmers/Shimmer";

const ExchangeProfile = () => {
  const { id } = useParams();
  console.log(id);
  const [exchangeData, setExchangeData] = useState(null);
  const [timeframe, setTimeframe] = useState("24h");
  const [isExpanded, setIsExpanded] = useState(false);
  const [exchangeNews, setExchangeNews] = useState();

  const truncateText = (text, wordLimit) => {
    const wordsArray = text.split(" ");
    return (
      wordsArray.slice(0, wordLimit).join(" ") +
      (wordsArray.length > wordLimit ? "..." : "")
    );
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getAdjustedVolume = (USD) => {
    switch (timeframe) {
      case "24h":
        return USD.adjusted_volume_24h;
      case "7d":
        return USD.adjusted_volume_7d;
      case "30d":
        return USD.adjusted_volume_30d;
      default:
        return USD.adjusted_volume_24h;
    }
  };

  useEffect(() => {
    const fetchExchange = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/global-data/getExchange/${id}`
        );
        console.log(response.data);
        setExchangeData(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchExchange();
  }, [id, setExchangeData]);

  useEffect(() => {
    const fetchExchangeNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/news/get/tags/${id}`
        );
        console.log(response.data);
        setExchangeNews(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchExchangeNews();
  }, [id, setExchangeNews]);

  if (!exchangeData) return <Shimmer width="30rem" height="20rem" borderRadius="1rem"/>;

  return (
    <Container fluid className="mx-3">
      <Row>
        <Col xs={12} lg={3} className={`${styles.exchangeInfo} text-start`}>
          <span className={styles.ranks}>#{exchangeData.adjusted_rank}</span>
          <div className={styles.heading}>
            {" "}
            <img
              src={exchangeData.img?.url}
              alt=""
              className={styles.exchangeLogo}
            />
            {exchangeData.name}
          </div>

          <Link
            to={exchangeData.links.website[0]}
            target="_blank"
            className={styles.linkBlock}
          >
            <IoIosLink /> Website
          </Link>
          <Link
            to={`https://twitter.com/${exchangeData.links.twitter[0]}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkBlock}
          >
            <IoLogoTwitter /> Twitter
          </Link>
          {exchangeData.fiats.length !== 0 ? (
            <Row
              className={`${styles.statistics} my-5 `}
              style={{ border: "1px solid #ddd", borderRadius: "8px" }}
            >
              <Col sm={12} className="mb-3">
                <h5 className="text-center">Fiats Available</h5>
              </Col>
              <Col sm={12} className="d-flex flex-column align-items-start">
                {exchangeData.fiats.map((fiat, index) => (
                  <div key={index} className={`${styles.fiatItem}`}>
                    <div>{fiat.name}</div>
                    <div>{fiat.symbol}</div>
                  </div>
                ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>

        <Col xs={12} lg={8}>
          <div className={`${styles.heading} mt-5`}>
            About {exchangeData.name}{" "}
          </div>
          <Row className={styles.statistics}>
            <Col xs={12} md={6} className={styles.tableContainer}>
              <Row className={styles.tableRow}>
                <Col
                  className={`${styles.tableCell} d-flex justify-content-between`}
                >
                  Spot Trading Values
                  <TimeframeDropdown
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                  />
                </Col>
                <Col className={styles.tableCell}>
                  ${getAdjustedVolume(exchangeData.quotes.USD)}
                </Col>
              </Row>
              <Row className={styles.tableRow}>
                <Col className={styles.tableCell}>Total Currencies</Col>
                <Col className={styles.tableCell}>
                  {exchangeData.currencies}
                </Col>
              </Row>
              <Row className={styles.tableRow}>
                <Col className={styles.tableCell}>Total Markets</Col>
                <Col className={styles.tableCell}>{exchangeData.markets}</Col>
              </Row>
              <Row className={styles.tableRow}>
                <Col className={styles.tableCell}>Session/Month</Col>
                <Col className={styles.tableCell}>
                  {exchangeData.sessions_per_month}
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex text-center mt-4 justify-content-center align-items-center"
            >
              <div className={styles.gaugeContainer}>
                <GaugeChart
                  id="gauge-chart"
                  nrOfLevels={32}
                  percent={exchangeData.confidence_score}
                  colors={["#FF5F6D", "#FFC371", "#4CAF50"]}
                  textColor="#000000"
                />
                <div
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  Confidence Score
                </div>
              </div>
            </Col>
          </Row>
          <Row className={styles.aboutSection}>
            <div className={styles.heading2}>What is {exchangeData.name}?</div>

            <div
              className={`${styles.description} ${
                !isExpanded ? styles.truncated : ""
              }`}
            >
              {isExpanded
                ? exchangeData.description
                : truncateText(exchangeData.description, 100)}
              {!isExpanded && <span className={styles.fadeEffect}></span>}
              <span className={styles.seeMore} onClick={handleToggle}>
                {isExpanded ? " see less" : "see more"}
              </span>
            </div>
          </Row>
          {exchangeNews ? (
            <Row className={styles.newsSection}>
              <div className={styles.heading2}>{exchangeData.name} News</div>
              <Col>
                {exchangeNews.map((data, index) => (
                  <Row className={`${styles.news} mx-4`} key={index}>
                    <Col md={7} className={`${styles.newsContent} pe-4`}>
                      <Row className={`${styles.newsHeading}`}>
                        {data.heading}
                      </Row>
                      <Row className={styles.newsSummary}>
                        {`${data.summary
                          ?.split(" ")
                          .slice(0, 50)
                          .join(" ")}...`}
                      </Row>
                    </Col>
                    <Col md={4} className={styles.imageContainer}>
                      <img
                        src={data.featuredImg.url}
                        className={styles.newsImage}
                        alt=""
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ExchangeProfile;

const TimeframeDropdown = ({ timeframe, setTimeframe }) => {
  // Handle the change event when user selects a new value
  const handleChange = (event) => {
    setTimeframe(event.target.value);
    console.log("Selected Timeframe:", event.target.value); // You can use this state to perform any action
  };

  return (
    <>
      <select
        id="timeframe"
        value={timeframe}
        className={styles.customDropdown}
        onChange={handleChange}
      >
        <option value="24h">24h</option>
        <option value="7d">7d</option>
        <option value="30d">30d</option>
      </select>
    </>
  );
};
