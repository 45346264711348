import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Container, Row } from 'react-bootstrap';

import { flashAction } from "../../store/customer/flashSlice";

const Flasher = ({ handleClose,styles }) => {
  const [animateClass, setAnimateClass] = useState(styles.flasherEnter);
  const flash = useSelector((store) => store.flashMessages) || '';
  const dispatch=useDispatch();

  useEffect(() => {
    if (flash.message) {
      setAnimateClass(styles.flasherEnter);
      
      const timer = setTimeout(() => {
        setAnimateClass(styles.flasherExit);
        
        setTimeout(() => {
          dispatch(flashAction.clearFlash());
        }, 500); 
      }, 3000); 

      return () => clearTimeout(timer);
    }
  }, [flash, dispatch]);
  return (
    <>
      {flash.message && (
        <Container className="d-flex fixed-top justify-content-center my-3">
          <Row>
            <Col>
              <Alert
                variant={flash.type === 'success' ? 'success' : 'danger'}
                dismissible
                className={`text-center ${animateClass}`} 
              >
                {flash.message}
              </Alert>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};


export default Flasher;
