import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfluencerById,
  selectSingleInfluncer,
} from "../../store/customer/influncerSlice";
import { useParams } from "react-router-dom";

function InfluncerProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(selectSingleInfluncer);

  console.log(data, "daata");

  useEffect(() => {
    if (id) {
      dispatch(fetchInfluencerById(id));
    }
  }, [dispatch, id]);
  return <div>InfluncerProfile</div>;
}

export default InfluncerProfile;
