import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import LatestMarketData from './LatestMarketData';
import { Container, Row, Col } from 'react-bootstrap';
import { GoSignOut } from "react-icons/go";

import style from './css/TopBar.module.css'
import { signout } from '../../store/customer/authSlice';
import { fetchTopbarData } from '../../store/customer/globalDataSlice';


const TopBar = () => {
  const dispatch = useDispatch();
  const isSignin = useSelector((state) => state.auth.isSignin);
  const{ topbarData, topbarStatus, error } = useSelector((state) => state.globalData);
  const [width, setWidth] = useState(window.innerWidth);

  // Using useCallback to memoize the handleResize function
  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  console.log(width);

  useEffect(() => {
    if (topbarStatus === "idle") {
      dispatch(fetchTopbarData());
    }
  }, [topbarStatus, dispatch]);
  const handleSignout = () => {

    dispatch(signout());

    
    localStorage.removeItem('token');
  };

  return (
    <Container fluid className={`${style.topbar} text-dark py-2`}>
      <Row >
    { topbarData && <Col  md={7} xs={6} className="d-flex flex-column flex-md-row">
  <LatestMarketData style={style}  topbarData={topbarData} topbarStatus={topbarStatus} error={error} width={width}/>
</Col>}
        <Col  md={5} xs={6} className="d-flex justify-content-end">
          <div className="d-flex align-items-center">
            {!isSignin ? (
              <>
                <Link to="signin" className={style.btnBlack}>Signin</Link>
                <Link to="signup" className={style.btnBlue}>SignUp</Link>
              </>
            ) : (<>
              <Link to="#" className={style.btnBlue}>Profile</Link>
              <Link to="#" className={style.btnBlue}>DashBoard</Link>
              {/* <Link to="#" className={style.btnBlue}>IndexByte Coin</Link> */}
              <Link to="#" onClick={handleSignout} className={style.btnBlue}><GoSignOut /></Link>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );

};

export default TopBar;
