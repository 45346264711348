import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { incrementAdClicks } from "../../../store/AdsSlice";

function BannerAd({ ad, onRemove }) {
  const dispatch = useDispatch();
  const handleAdClick = () => {
    dispatch(incrementAdClicks(ad._id));
  };

  return (
    <Card className="mb-4 position-relative mx-2">
      

      <Link to={ad.webUrl} target="_blank" onClick={handleAdClick}>
        <Card.Img variant="top" src={ad.img?.url} alt={ad.img?.fileName} />
      </Link>
    </Card>
  );
}

export default BannerAd;
