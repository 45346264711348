import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsCard from "../../Components/customer/NewsCard";

const TagSearch=()=>{
const { tag } = useParams();
const [newsList,setNewsList]=useState(null);
  if(tag){
    const response= axios.get(`${process.env.REACT_APP_BASE_URL}/api/news/get/tags/${tag}`)
    .then((response)=>{
      console.log(response);
      setNewsList(response.data);

    }).catch((err)=>{
      console.log(err);
    })
  }else{
    console.log("Tag is not found");
  }



  return (
    <>
      <Container fluid className='d-flex'>
        {/* <DynamicBreadcrumb /> */}
        {/* <input placeholder="Search news..." />  */}
      </Container>
      
      <Container fluid>
        {newsList.map((newsItem) => (
          <Row key={newsItem.id}>
            <Col md={1} className="mb-4"></Col>
            <Col md={10} className="mb-4">
              <NewsCard
                news={newsItem}
              />
            </Col>
          </Row>
        ))}
      </Container>
    </>
  );
}

export default TagSearch;

// router.get('get/tags/:tag',getNewsBytags)
// const getNewsByTags= async (req, res) => {
//   const news= await News.find({ tags: req.params.tag });
//   if (news.length === 0) {
//     return res.status(404).json({ message: 'No posts found for this tag.' });
// }
// res.json(news);
// } catch (error) {
// res.status(500).json({ message: 'Error: ' + error.message });
// }
// });