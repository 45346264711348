import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTopGainers = createAsyncThunk(
  "globalData/fetchTopGainers",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/getTopGainers`
    );
    return response.data;
  }
);

export const fetchTopLosers = createAsyncThunk(
  "globalData/fetchTopLosers",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/getTopLosers`
    );
    return response.data;
  }
);

const initialState = {
  topGainers: [],
  topLosers: [],
  gainerLooserStatus: "idle",
  error: null,
};

const gainerLooserSlice = createSlice({
  name: "gainerLooser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopGainers.pending, (state) => {
        state.gainerLooserStatus = "loading";
      })
      .addCase(fetchTopGainers.fulfilled, (state, action) => {
        state.gainerLooserStatus = "succeeded";

        state.topGainers = action.payload;
      })
      .addCase(fetchTopGainers.rejected, (state, action) => {
        state.gainerLooserStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchTopLosers.pending, (state) => {
        state.gainerLooserStatus = "loading";
      })
      .addCase(fetchTopLosers.fulfilled, (state, action) => {
        state.gainerLooserStatus = "succeeded";
        state.topLosers = action.payload;
      })
      .addCase(fetchTopLosers.rejected, (state, action) => {
        state.gainerLooserStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectTopGainers = (state) => state.gainerLooser.topGainers;
export const selectTopLosers = (state) => state.gainerLooser.topLosers;
export const selectGainerLooserStatus = (state) => state.gainerLooser.gainerLooserStatus;
export const selectError = (state) => state.gainerLooser.error;

export default gainerLooserSlice.reducer;
