import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { FaCaretDown, FaCaretUp, FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formateCoinPrice } from "../../utils/common/formateNumber";

const CryptoCurrency = ({ styles, data, Name , to }) => {
  return (
    <Container fluid>
      <Card className={`${styles.homeCard}`}>
        <Row className="align-items-center mb-1 g-0">
          <Col className="d-flex justify-content-between">
            <p
              className={`${styles.smallText} ${styles.smallHead} font-weight-bold`}
            >
              🚀 {Name}
            </p>
            <p className="text-right ">
              <small>
                <Link to={to} className="text-reset">
                <FaExternalLinkAlt size={12} />
                </Link>
              </small>
            </p>
          </Col>
        </Row>

        {data?.slice(0, 6)?.map((list, index) => (
          <Row key={index} className="align-items-center mb-1">
            <Col className="d-flex justify-content-between">
            <Link to={`/coin-profile/${list.id}`} className={`${styles?.smallText}`}>
              <p >
                <img
                  src={list?.img?.url}
                  width="16px"
                  alt={list?.name}
                  className="mr-2 me-2"
                />
                {list?.name}
              </p>
              </Link>
              <p className={`${styles.smallText} mb-0 text-start d-flex `}>
                <span className="font-weight-bold px-1">
                  $
                  {list?.close?.toFixed(6) ||
                    formateCoinPrice(list?.quotes?.USD?.price)}
                </span>
                <br />
                <span
                  className={
                    list?.changes > 0 ||
                    list?.quotes?.USD?.market_cap_change_24h > 0
                      ? styles.pUp
                      : styles.pDown
                  }
                >
                  {list?.changes > 0 ||
                  list?.quotes?.USD?.market_cap_change_24h > 0 ? (
                    <FaCaretUp />
                  ) : (
                    <FaCaretDown />
                  )}
                  {Math.abs(
                    list?.changes || list?.quotes?.USD?.market_cap_change_24h
                  )?.toFixed(2)}
                  %
                </span>
              </p>
            </Col>
          </Row>
        ))}
      </Card>
    </Container>
  );
};

export default CryptoCurrency;
