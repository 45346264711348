import React from "react";

import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "react-bootstrap";
import { useDispatch,  } from "react-redux";

import {  useNavigate} from "react-router-dom";
import axios from "axios";
import { signin } from "../../store/customer/authSlice";
import { flashAction } from "../../store/customer/flashSlice";

const GoogleSign = () => {

    const dispatch = useDispatch();
  const navigate = useNavigate();


  const responseGoogle = async (authResult) => {
    try {
      if (authResult.code) {
        const result = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/auth/google?code=${authResult.code}`
        );
        const { token, message, userType, userId } = result.data;

          dispatch(signin({ token, userId, userType }));
          dispatch(flashAction.addFlash({message,type:"success"}));
          
          navigate("/");
      }
    } catch (err) {
      dispatch(flashAction.addFlash({message:err.message,type:"Fail"}));
      console.log("Error:" + err);
    }
  };
  const googleSignin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: "auth-code",
  });

  return (
    <div className="d-flex justify-content-center mt-3">
      <Button
        variant="outline-dark"
        className="w-100 mb-2"
        onClick={googleSignin}
      >
        <i className="bi bi-google me-2"></i>Continue with Google
      </Button>
    </div>
  );
};

export default GoogleSign;
