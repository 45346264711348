import React from 'react';
import NavBar from "./NavBar"
import TopBar from "./TopBar";
import Flasher from './flashers';
import { useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import { flashAction } from '../../store/customer/flashSlice';
import UperNavBar from './UpperNavbar';
import styles from './css/Header.module.css';


const Header=()=>{
    
    const [show, setShow] = useState(true);
    
    const handleClose = () =>{ setShow(false);
        flashAction.clearFlash();
    }

    const location = useLocation(); 

    const hideTopBar = ["/signin", "/signup", "/forget-pass", "/otpSent", "/reset-pass"].includes(location.pathname);
    


    
    return(
        <>
        {!hideTopBar && <TopBar />}
        <NavBar styles={styles}/>

        
        {show && (
        <Flasher
          handleClose={handleClose}
          styles={styles}
        />
      )}
        
        </>
    )
}

export default Header;