

const socket = new WebSocket(process.env.REACT_APP_WS_URL); 

socket.onopen = () => {
  console.log('WebSocket Client Connected');
};

socket.onmessage = (message) => {
  console.log('Received:', message.data);
};


export default socket;
