import moment from "moment";
import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function TopNews({ styles, data, col = 4, maxNews=4}) {
const filteredData = data
  ?.filter((item) => item.type === "market news")
  .slice(0, maxNews);

  return (
    <Card className={`${styles?.homeCard}`}>
      <Container>
        {/* <Row className="align-items-center justify-content-between">
          <Col className={`${styles.TopNews}`}>
            <h2 className="fw-bold fs-md-6 lh-lg">Top News</h2>
          </Col>
        </Row> */}

        <Row xs={12} md={col}>
          {filteredData?.map((list, index) => (
            <div key={index} className="align-items-center mb-1 ">
              <div className={`${styles?.newsImg}  d-flex align-items-end`}>
                <Link to={`/market-news/${list?.slug}`}>
                  <img
                    src={list?.featuredImg?.url}
                    alt="news img"
                    className={`img-fluid`}
                  />
                </Link>
              </div>
              <div>
                <Link
                  to={`/market-news/${list?.slug}`}
                  className={`${styles?.newsHeading}`}
                >
                  {list?.heading}
                </Link>
                <p className={`${styles?.newsTimer} d-flex align-items-center`}>
                  {moment(list?.createdAt).format("DD MMM YYYY  h:mm a")}
                </p>
              </div>
              <div>
                <p
                  className={`${styles?.newSummary} d-flex align-items-center`}
                >
                  {`${list.summary?.split(" ").slice(0, 30).join(" ")}...`}
                </p>
              </div>
            </div>
          ))}

          {/* <Link className={`${styles.newsBtn}`} to="/market-news">
              View All
            </Link> */}
        </Row>
      </Container>
    </Card>
  );
}

export default TopNews;
