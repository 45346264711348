import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAds = createAsyncThunk("ads/fetchAds", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/ads/get`
  );
  return response.data;
});

export const incrementAdClicks = createAsyncThunk(
  "ads/incrementAdClicks",
  async (adId) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/ads/adClick/${adId}`
    );
    return { adId, clicks: response.data.clicks };
  }
);



const AdsSlice = createSlice({
  name: "ads",
  initialState: {
    ads: [],
    status: "idle",
    loading: false,
    error: null,
  },
  reducers: {
    setAds: (state, action) => {
      state.ads = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ads = action.payload;
      })
      .addCase(fetchAds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(incrementAdClicks.fulfilled, (state, action) => {
        const adIndex = state.ads.findIndex(
          (ad) => ad._id === action.payload.adId
        );
        if (adIndex !== -1) {
          state.ads[adIndex].clicks = action.payload.clicks;
        }
      });
  },
});


export const selectAds = (state) => state.ads.ads;
export const { setAds, setLoading, setError } = AdsSlice.actions;

export default AdsSlice.reducer;
