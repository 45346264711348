import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUpcomingCryptos = createAsyncThunk(
  "cryptos/fetchUpcomingCryptos",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/upcomingCrypto/get`
    );

    return response.data;
  }
);

export const addUpcomingCrypto = createAsyncThunk(
  "cryptos/addUpcomingCrypto",
  async (cryptoData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/upcomingCrypto/add`,
      cryptoData
    );
    return response.data;
  }
);

export const updateUpcomingCrypto = createAsyncThunk(
  "cryptos/updateUpcomingCrypto",
  async ({ id, cryptoData }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/upcomingCrypto/edit/${id}`,
      cryptoData
    );
    return response.data;
  }
);

export const deleteUpcomingCrypto = createAsyncThunk(
  "cryptos/deleteUpcomingCrypto",
  async (id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/upcomingCrypto/delete/${id}`
    );
    return response.data;
  }
);

const UpcommingCryptoSlice = createSlice({
  name: "upcomingCryptos",
  initialState: {
    list: [],
    status: "idle",
    error: null,
  },
  reducers: {
    clearCryptos: (state, action) => {
      const idToRemove = action.payload;
      state.list = state.list.filter((item) => item._id !== idToRemove);
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchUpcomingCryptos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUpcomingCryptos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchUpcomingCryptos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addUpcomingCrypto.fulfilled, (state, action) => {
        state.list.push(action.payload.data);
      })

      .addCase(updateUpcomingCrypto.fulfilled, (state, action) => {
        const updatedCrypto = action.payload;
        state.list = state.list.map((item) =>
          item._id === updatedCrypto._id ? updatedCrypto : item
        );
      })

      .addCase(deleteUpcomingCrypto.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.list = state.list.filter((item) => item._id !== id);
      });
  },
});

export const { clearCryptos } = UpcommingCryptoSlice.actions;

export default UpcommingCryptoSlice.reducer;

