// DynamicBreadcrumb.js
import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const DynamicBreadcrumb = () => {
  const location = useLocation();

  // Split the current path into segments
  const pathnames = location.pathname.split('/').filter(x => x);

  // Function to capitalize each word
  const capitalizeWords = (str) => {
    return str
      .split(' ') // Split by space
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); // Join them back
  };

  return (
    <Container fluid className='breadCrumb-custom'>
  <Breadcrumb>
    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
      Home
    </Breadcrumb.Item>
    {pathnames
      .filter(value => !value.startsWith('0'))
      .map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        // Replace hyphen with space and capitalize each word
        const formattedValue = capitalizeWords(value.replace(/-/g, ' '));

        return isLast ? (
          <Breadcrumb.Item active key={to}>
            {formattedValue}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item linkAs={Link} linkProps={{ to }} key={to}>
            {formattedValue}
          </Breadcrumb.Item>
        );
      })}
  </Breadcrumb>
</Container>

  );
};

export default DynamicBreadcrumb;
