import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NewsCard from "../../Components/customer/NewsCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews, SelectNews } from "../../store/customer/newsFetchSlice";
import DynamicBreadcrumb from "../../Components/customer/BreadCrumb";
import styles from "./css/NewsCard.module.css";
import Tickers from "../../Components/customer/Tickers";
import style from "./css/Home.module.css";
import Shimmer from "../../Components/shimmers/Shimmer.jsx";

const NewsPage = ({ to }) => {
  const dispatch = useDispatch();
  const { newsList, page, totalPages, newsStatus, error } = useSelector(SelectNews);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollPosition = useRef(window.scrollY);
  const itemsPerPage = 2;

  useEffect(() => {
    const fetchNewsData = async () => {
      dispatch(
        fetchNews({
          type: to.replace("-", " "),
          page: currentPage,
          limit: itemsPerPage,
        })
      );
    };

    fetchNewsData();
  }, [dispatch, to, currentPage, itemsPerPage]);

  useEffect(() => {
    const handleScroll = () => {
      scrollPosition.current = window.scrollY;
      const nearBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 50;
      if (nearBottom && currentPage < totalPages) {
        setCurrentPage((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, totalPages]);

  if (newsStatus === 'loading') return <div><Shimmer width='100%' height='100px' /></div>;
  if (newsStatus === 'failed') return <div>Error: {error}</div>;

  const formattedPageTitle = to
    ?.replace(/-/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const renderContent = (items) => {
    switch (to) {
      case "market-news":
        return (
          <Container fluid>
            <Row className="mx-2">
              <Col md={12}>
                {items?.map((newsItem) => (
                  <NewsCard
                    key={newsItem.id}
                    news={newsItem}
                    styles={styles}
                    page={to}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        );
      case  "market-analysis":
        return (
          <Row className="mx-3">
            {items.map((newsItem) => (
              <Col key={newsItem.id} xs={12} sm={6} md={4} className="mb-4">
                <NewsCard news={newsItem} styles={styles} page={to} />
              </Col>
            ))}
          </Row>
        );
      case "market-reports":
        return (
          <Container fluid className={styles.mrFeedContainer}>
            <Row className="mx-3">
              {items.map((newsItem) => (
                <Col md={4} className="mb-4" key={newsItem.id}>
                  <NewsCard news={newsItem} styles={styles} page={to} />
                </Col>
              ))}
            </Row>
          </Container>
        );
      case "analytics":
        return (
          <Container fluid className={styles.aContainer}>
            <Row className="mx-3 mt-4">
              {items.map((newsItem) => (
                <NewsCard
                  news={newsItem}
                  styles={styles}
                  page={to}
                  key={newsItem.id}
                />
              ))}
            </Row>
          </Container>
        );
      case "articles":
        return (
          <Container fluid>
            <Row className="mx-3">
              <Col md={12}>
                {items.map((newsItem) => (
                  <NewsCard
                    key={newsItem.id}
                    news={newsItem}
                    styles={styles}
                    page={to}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        );
      default:
        return <h1>You have come to a wrong URL, please go back.</h1>;
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Tickers styles={style} />
        </Row>
        <Row className="mx-3">
          <DynamicBreadcrumb />
        </Row>

        <h2 className={styles.mrFeedTitle}>{formattedPageTitle}</h2>

        {renderContent(newsList)}
      </Container>
    </>
  );
};

export default NewsPage;
