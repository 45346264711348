import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import { Container } from "react-bootstrap";
import BannerAd from "./BannerAd";
import ResponsiveAd from "./ResponsiveAd";
import CodeAd from "./CodeAd";
import { fetchAds, selectAds } from "../../../store/AdsSlice";

const Ads = ({ type }) => {
  const dispatch = useDispatch();
  const allAds = useSelector(selectAds);
  const loading = useSelector((state) => state.ads.loading);
  const status = useSelector((state) => state.ads.status);
  const error = useSelector((state) => state.ads.error);

  const [selectedAd, setSelectedAd] = useState(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAds());
    }
  }, [dispatch, status]);

  useEffect(() => {
    const filteredAds = allAds.filter((ad) => ad.adType === type);

    if (filteredAds.length > 0) {
      const randomAd =
        filteredAds[Math.floor(Math.random() * filteredAds.length)];
      setSelectedAd(randomAd);
    } else {
      setSelectedAd(null);
    }
  }, [allAds, type]);

  const handleRemoveAd = () => {
    setSelectedAd(null);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

 

  return (
    <Container className="ads-container mt-4">
      {selectedAd?.adType === "banner" && (
        <BannerAd
          key={selectedAd._id}
          ad={selectedAd}
          onRemove={handleRemoveAd}
        />
      )}
      {selectedAd?.adType === "responsive" && (
        <ResponsiveAd
          key={selectedAd._id}
          ad={selectedAd}
          onRemove={handleRemoveAd}
        />
      )}
      {selectedAd?.adType === "code-x" && (
        <CodeAd key={selectedAd._id} ad={selectedAd} />
      )}
    </Container>
  );
};

export default Ads;
