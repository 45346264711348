import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNews = createAsyncThunk('news/fetchNews', async ({ type, page, limit }) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/news/get?type=${encodeURIComponent(type)}&page=${page}&limit=${limit}`);
  response.data.type=type;
    console.log(response.data);
  return response.data;
});

const newsFetchSlice = createSlice({
  name: "news",
  initialState: {
    newsList: [],
    total: 0,
    page: 1,
    totalPages: 1,
    newsStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.newsStatus = "loading";
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.newsStatus = 'succeeded';
        if(state.newsList.length === 0 || action.payload.type===state.newsList[0].type){
        action.payload.news.forEach(newItem => {
          if (!state.newsList.some(existingItem => existingItem.slug === newItem.slug)) {
              state.newsList.push(newItem);
          }
      });
    }else{
      state.newsList = action.payload.news
    }
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.newsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const SelectNews = (state) => state.news;
export const selectNewsList = (state) => state.news.newsList;

export default newsFetchSlice;
