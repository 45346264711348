import React, { useEffect, useState } from "react";
import { Card, Image, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CiBookmark } from "react-icons/ci";
import { IoShareSocial } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import { MdOutlineInsertComment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { flashAction } from "../../store/customer/flashSlice";
import axios from "axios";
import { GoComment } from "react-icons/go";

const NewsCard = ({ news, styles, page }) => {
  const [likes, setLikes] = useState(news?.likes?.length);
  const [isLiked, setIsLiked] = useState(false);
  const [userId, setUserId] = useState(null);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const date = new Date(news?.updatedAt).toLocaleDateString("en-GB", options);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    setUserId(storedUserId);
    if (storedUserId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/news/${news?._id}/isLikeByUser/${storedUserId}`
        )
        .then((response) => {
          if (response.data.isLiked) {
            setIsLiked(true);
          }
        })
        .catch((error) => {
          console.error("Error checking if liked", error);
        });
    }
  }, [news?._id]);

  const handleLike = async () => {
    if (isSignin) {
      try {
        if (!isLiked) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${news?._id}/like/${userId}`
          );
          setLikes(likes + 1);
          setIsLiked(true);
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${news?._id}/unlike/${userId}`
          );
          setLikes(likes - 1);
          setIsLiked(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  if (page === "market-news") {
    return (
      <Card className={styles.card}>
        <Row className={styles.noGutters}>
          <Col md={4} xs={12} className={styles.positionRelative}>
            <div className={styles.textCenter}>
              <Link className={styles.bookmarkIcon}>
                <CiBookmark size={20} />
              </Link>
            </div>
            <Link
              to={`/${page}/${news?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card.Img
                src={news?.featuredImg?.url}
                alt={news?.featuredImg?.fileName}
                className={styles?.cardImg}
              />
            </Link>
          </Col>

          <Col md={8} xs={12}>
            <Card.Body className={styles.cardBody}>
              <Link
                to={`/${page}/${news?.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card.Title>{news?.heading}</Card.Title>
              </Link>
              <div className="mb-5">
                <div
                  className={`${styles.newDateTime} d-flex justify-content-between`}
                >
                  {news?.category} | {date}
                </div>
                <Card.Text className={styles.newsSummary}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${news?.summary
                        ?.split(" ")
                        .slice(0, 50)
                        .join(" ")}...`,
                    }}
                  />
                </Card.Text>
              </div>

              <div className={styles.bottomSection}>
                {/* <div className={styles.authorInfo}>
                <Image
                  src={`${process.env.REACT_APP_BASE_URL}${news?.author?.imgurl}`}
                  className={styles.authorImg}
                  alt="Author"
                />
                <div>
                  <small className="text-muted">{news?.author?.name}</small>
                </div>
              </div> */}
                <div className={styles.iconSection}>
                  <span className={styles.iconWrapper}>
                    <svg width="0" height="0">
                      <defs>
                        <linearGradient
                          id="likeGradient"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: "#1e30f3", stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{ stopColor: "#e21e80", stopOpacity: 1 }}
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <BiLike
                      size={16}
                      onClick={handleLike}
                      className={`${styles.likeIcon} ${
                        isLiked ? styles.liked : styles.notLiked
                      }`}
                    />{" "}
                    {likes}
                  </span>
                  <span className={styles.iconWrapper}>
                    <GoComment className={styles.notLiked} size={16} />
                    {news?.comments}
                  </span>
                  <span className={styles.iconWrapper}>
                    <IoShareSocial size={16} /> {news?.shares}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  } else if (page === "market-analysis") {
    return (
      <Card className={styles.macard}>
        <Link
          to={`/${page}/${news?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card.Img
            src={news.featuredImg?.url}
            alt={news.featuredImg.fileName}
            className={styles.macardImg}
          />
        </Link>

        <div className={styles.matextCenter}>
          <Link to={`${process.env.REACT_APP_BASE_URL}/addToBookmark/${userId}/${news?.slug}`} className={styles.mabookmarkIcon}>
            <CiBookmark size={20} />
          </Link>
        </div>

        <Card.Body className={styles.macardBody}>
          <Link
            to={`/${page}/${news?.slug}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card.Title>{news.heading}</Card.Title>
          </Link>
          <div className="mb-5">
            <div className="d-flex justify-content-between">
              <div className={styles.manewDateTime}>
                {news.category} | {date}
              </div>
            </div>
            <Card.Text className={styles.manewsSummary}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${news.summary
                    ?.split(" ")
                    .slice(0, 30)
                    .join(" ")}...`,
                }}
              />
            </Card.Text>
          </div>

          <div className={styles.mabottomSection}>
            <div className={styles.maauthorInfo}>
              <Image
                src={news?.author?.imgurl}
                className={styles.maauthorImg}
                alt="Author"
              />
              <div>
                <small className="text-muted">{news?.author?.name}</small>
              </div>
            </div>
            <div className={styles.maiconSection}>
              <span className={styles.maiconWrapper}>
                <svg width="0" height="0">
                  <defs>
                    <linearGradient
                      id="likeGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#1e30f3", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#e21e80", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                </svg>
                <BiLike
                  size={16}
                  onClick={handleLike}
                  className={`${styles.malikeIcon} ${
                    isLiked ? styles.maliked : styles.manotLiked
                  }`}
                />{" "}
                {likes}
              </span>
              <span className={styles.maiconWrapper}>
                <GoComment className={styles.notLiked} size={16} />{" "}
                {news?.comments}
              </span>
              <span>
                <IoShareSocial size={16} /> {news?.shares}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  } else if (page === "articles") {
    return (
      <Card className={styles.card}>
        <Row className={styles.noGutters}>
          <Col md={4} xs={12} className={styles.positionRelative}>
            <div className={styles.textCenter}>
              <Link className={styles.bookmarkIcon}>
                <CiBookmark size={20} />
              </Link>
            </div>
            <Link
              to={`/${page}/${news?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card.Img
                src={news.featuredImg?.url}
                alt={news.featuredImg.fileName}
                className={styles.cardImg}
              />
            </Link>
          </Col>

          <Col md={8} xs={12}>
            <Card.Body className={styles.cardBody}>
              <Link
                to={`/${page}/${news?.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card.Title>{news.heading}</Card.Title>
              </Link>
              <div className="mb-5">
                <div className="d-flex justify-content-between">
                  <div className={styles.newDateTime}>
                    {news.category} | {date}
                  </div>
                </div>
                <Card.Text className={styles.newsSummary}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${news.summary
                        ?.split(" ")
                        .slice(0, 50)
                        .join(" ")}...`,
                    }}
                  />
                </Card.Text>
              </div>

              <div className={styles.bottomSection}>
                <div className={styles.authorInfo}>
                  <Image src={news?.author?.imgurl} alt="Author" />
                  <div>
                    <small className="text-muted">{news?.author?.name}</small>
                  </div>
                </div>
                <div className={styles.iconSection}>
                  <span className={styles.iconWrapper}>
                    <svg width="0" height="0">
                      <defs>
                        <linearGradient
                          id="likeGradient"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: "#1e30f3", stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{ stopColor: "#e21e80", stopOpacity: 1 }}
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <BiLike
                      size={16}
                      onClick={handleLike}
                      className={`${styles.likeIcon} ${
                        isLiked ? styles.liked : styles.notLiked
                      }`}
                    />{" "}
                    {likes}
                  </span>
                  <span className={styles.iconWrapper}>
                    <GoComment className={styles.notLiked} size={16} />{" "}
                    {news?.comments}
                  </span>
                  <span>
                    <IoShareSocial size={16} /> {news?.shares}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  } else if (page === "analytics") {
    return (
      <>
        {/* <div className={styles.mrfilterContainer}>
      <Button variant="outline-primary">Search Blog</Button>
      <Button variant="outline-primary">Select Category</Button>
    </div> */}

        <Col md={6}>
          <Card className={styles.acustomCard}>
            <Link
              to={`/${page}/${news?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card.Img
                variant="top"
                src={news.featuredImg?.url}
                alt={news.featuredImg.fileName}
                className={styles.aresponsiveImage}
              />
            </Link>
            <Card.Body>
              <Link
                to={`/${page}/${news?.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card.Title>{news.heading}</Card.Title>
              </Link>

              <Card.Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${news.summary
                      ?.split(" ")
                      .slice(0, 28)
                      .join(" ")}...`,
                  }}
                />
              </Card.Text>
              <Card.Text>
                <small className="text-muted">{news?.author?.name}</small>
                <br />
                <small>
                  {news.category} | {date}
                </small>
              </Card.Text>
              <div className={styles.iconSection}>
                <span className={styles.iconWrapper}>
                  <svg width="0" height="0">
                    <defs>
                      <linearGradient
                        id="likeGradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "#1e30f3", stopOpacity: 1 }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "#e21e80", stopOpacity: 1 }}
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  <BiLike
                    size={16}
                    onClick={handleLike}
                    className={`${styles.likeIcon} ${
                      isLiked ? styles.liked : styles.notLiked
                    }`}
                  />{" "}
                  {likes}
                </span>
                <span className={styles.iconWrapper}>
                  <GoComment className={styles.notLiked} size={16} />{" "}
                  {news?.comments}
                </span>
                <span>
                  <IoShareSocial size={16} /> {news?.shares}
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  } else if (page === "market-reports") {
    return (
      <Link
        to={`/${page}/${news?.slug}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Card className={styles.mrCard}>
          <Card.Body>
            {/* <Badge className={styles.mrcategoryBadge}>{news.category}</Badge> */}
            <Link
              to={`/${page}/${news?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card.Title className={styles.mrcardTitle}>
                {news.heading}
              </Card.Title>
            </Link>
            <Card.Text className={styles.mrtime}>{date}</Card.Text>
            <Card.Text
              className={styles.mrdescription}
              dangerouslySetInnerHTML={{
                __html: `${news.summary?.split(" ").slice(0, 30).join(" ")}...`,
              }}
            />
            <Row className={styles.mrinteractionRow}>
              <Col className={styles.mrinteractionItem}>
                <BiLike
                  size={14}
                  onClick={handleLike}
                  className={`${styles.likeIcon} ${
                    isLiked ? styles.liked : styles.notLiked
                  }`}
                />{" "}
                {likes}
              </Col>
              <Col className={styles.mrinteractionItem}>
                <MdOutlineInsertComment size={14} /> {news?.comments}
              </Col>
              <Col className={styles.mrinteractionItem}>
                <IoShareSocial size={14} /> {news?.shares}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Link>
    );
  }
};

export default NewsCard;
