import { createBrowserRouter } from "react-router-dom";
import App from "./Pages/customer/App";
import Home from "./Pages/customer/Home";
import SignIn from "./Pages/customer/SignIn";
import OtpVarification from "./Pages/customer/OtpVarification";
import ResetPassword from "./Pages/customer/ResetPassword";
import NewsPage from "./Pages/customer/NewsPage";
import NewsDetail from "./Pages/customer/NewsDetail";
import TagSearch from "./Pages/customer/TagSearch";
import NewsListingcoins from "./Pages/customer/NewsListingcoins";
import ListingDetails from "./Components/customer/ListingDetails";
import TopGainerLoser from "./Pages/customer/TopGainerLoser";
import TopExchange from "./Pages/customer/TopExchange";
import UpcomingProjects from "./Pages/customer/UpcomingProjects";
import ExchangeProfile from "./Pages/customer/ExchangeProfile";
import CoinProfile from "./Pages/customer/CoinProfile";
import TopInfluencer from "./Pages/customer/TopInfluencer";
import InfluncerProfile from "./Pages/customer/InfluncerProfile";



export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/signin", element: <SignIn to="signin" /> },
      { path: "/signup", element: <SignIn to="signup" /> },
      { path: "/forget-pass", element: <SignIn to="forget-pass" /> },
      { path: "/otpSent", element: <OtpVarification /> },
      { path: "/reset-pass", element: <ResetPassword /> },
      {
        path: "/market-news",
        element: <NewsPage key="market-news" to="market-news" />,
      },
      {
        path: "/market-reports",
        element: <NewsPage key="market-reports" to="market-reports" />,
      },
      {
        path: "/market-analysis",
        element: <NewsPage key="market-analysis" to="market-analysis" />,
      },
      { path: "/articles", element: <NewsPage key="articles" to="articles" /> },
      {
        path: "/analytics",
        element: <NewsPage key="analystics" to="analytics" />,
      },
      { path: "/:page/:slug", element: <NewsDetail /> },
      {
        path: "/analytics",
        element: <NewsPage key="analystics" to="analytics" />,
      },
      { path: "/:page/:id", element: <NewsDetail /> },
      { path: "/news/tags/:tag", element: <TagSearch /> },
      { path: "/newListings", element: <NewsListingcoins /> },
      { path: "/top100", element: <ListingDetails /> },
      { path: "/top-gainer-looser", element: <TopGainerLoser /> },
      { path: "/top-100-exchange", element: <TopExchange /> },
      { path: "/upcoming-projects", element: <UpcomingProjects /> },
      { path: "/exchange-profile/:id", element: <ExchangeProfile /> },
      { path: "/coin-profile/:id", element: <CoinProfile /> },
      { path: "/top-100-influncer", element: <TopInfluencer /> },
      { path: "/top-100-influncer/:id", element: <InfluncerProfile /> },
    ],
  },
]);
