import React from 'react';
import styles from './Shimmer.module.css';

const Shimmer = ({ width = '100%', height = '200px', borderRadius = '8px' }) => {
  return (
    <div
      className={styles.shimmerWrapper}
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
      }}
    />
  );
};

export default Shimmer;
