import React, { useCallback, useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp, FaDollarSign } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import { formatNumber } from "../../utils/common/formateNumber";
import Shimmer from "../shimmers/Shimmer";


const LatestMarketData = ({style, topbarData, topbarStatus, error, width}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fade, setFade] = useState("fade-in");
  

  const {
    market_cap_usd,
    volume_24h_usd,
    bitcoin_dominance_percentage,
    market_cap_change_24h,
    volume_24h_change_24h,
  } = topbarData;


  const data = [
    {
      label: "Market",
      value: `$${formatNumber(market_cap_usd)}`,
      change: market_cap_change_24h,
    },
    {
      label: "24h Vol",
      value: `$${formatNumber(volume_24h_usd)}`,
      change: volume_24h_change_24h,
    },
    {
      label: "Dominance",
      value: `${Math.abs(bitcoin_dominance_percentage)}%`,
    },
  ];
  

  useEffect(() => {
    const interval = setInterval(() => {
      setFade("fade-out"); 
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
        setFade("fade-in"); 
      }, 800);
    }, 5000); 
    return () => clearInterval(interval);
  }, [data.length]);

  const currentItem = data[activeIndex];
  
  if (topbarStatus === "loading") {
    return <Container fluid>
    <Row className="">
    <Col md={4} className={`${style.lmd} g-2 d-flex`}>
      Market: 
    <Shimmer width="1rem" height="0.5rem" borderRadius="0.1rem"/>
    </Col>

    <Col  md={4} className={`${style.lmd} g-2 d-flex`}>
      24h vol:
      <Shimmer width="1rem" height="0.5rem" borderRadius="0.1rem"/>
    </Col>

    <Col md={4} className={` ${style.lmd} g-2 d-flex`}>Dominance: <Shimmer width="1rem" height="0.5rem" borderRadius="0.1rem"/></Col>
    </Row>
  </Container>;
  }

  if (topbarStatus === "failed") {
    return <div>Error: {error}</div>;
  }


  if (!topbarData) {
    return <div>No data available</div>;
  }


  

  
if(width<=576){
  return (
    <Container fluid>
      <Row className="justify-content-start">
        <Col
          md={4}
          className={`${style.lmd} g-2 d-flex justify-content-center align-items-center ${style[fade]}`}
        >
          <span>{currentItem.label}:</span>
          <span className="fw-bold mx-1">{currentItem.value}</span>
          {currentItem.change !== undefined && (
            <span
              className={
                currentItem.change >= 0 ? "text-success" : "text-danger"
              }
            >
              {currentItem.change >= 0 ? <FaCaretUp /> : <FaCaretDown />}
              {Math.abs(currentItem.change)}%
            </span>
          )}
        </Col>
      </Row>
    </Container>
  );
}else{
  return (<Container fluid>
      <Row className="">
      <Col md={4} className={`${style.lmd} g-2 d-flex`}>
        Market: 
       <span className="fw-bold mx-1"> ${formatNumber(market_cap_usd)} </span>
        <span
          className={
            market_cap_change_24h >= 0 ? "text-success" : "text-danger"
          }
        >
          {market_cap_change_24h >= 0 ? <FaCaretUp /> : <FaCaretDown />}
          {Math.abs(market_cap_change_24h)}%
        </span>
      </Col>

      <Col  md={4} className={`${style.lmd} g-2 d-flex`}>
        24h vol:
        <span className="fw-bold mx-1">${formatNumber(volume_24h_usd)} </span>
        <span
          className={
            volume_24h_change_24h >= 0 ? "text-success" : "text-danger"
          }
        >
          {volume_24h_change_24h >= 0 ? <FaCaretUp /> : <FaCaretDown />}
          {Math.abs(volume_24h_change_24h)}%
        </span>
      </Col>

      <Col md={4} className={` ${style.lmd} g-2 d-flex`}>Dominance:  <span className=" ms-2 fw-bold mx-1"> {Math.abs(bitcoin_dominance_percentage)}%</span></Col>
      </Row>
    </Container>)
}
};

export default LatestMarketData;
