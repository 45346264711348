import React, { useEffect, useRef, memo } from "react";

function TradingViewChart({ symbol = "BTCUSD", styles }) {
  const container = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "en",
      allow_symbol_change: true,
      calendar: false,
      container_id: "tradingview_widget",
    });

    if (container.current) {
      container.current.innerHTML = "";
      container.current.appendChild(script);
    }

    return () => {
      if (container.current) {
        container.current.innerHTML = "";
      }
    };
  }, [symbol]);

  return (
    <div className={styles?.tradingviewWidgetContainer} ref={container}>
      <div
        id="tradingview_widget"
        className={styles?.tradingviewWidgetContainerWidget}
      ></div>
    </div>
  );
}

export default memo(TradingViewChart);
