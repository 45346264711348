import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";




export const fetchExchange = createAsyncThunk(
    "globalData/fetchExchange",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/global-data/getExchange`
      );
  
      return response.data;
    }
  );

  const initialState = {
    exchangeList: [],
    exchnagestatus: "idle",
    error: null,
  };

  const exchangeSlice = createSlice({
    name: "exchangeData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchExchange.pending, (state) => {
          state.exchnagestatus = "loading";
        })
        .addCase(fetchExchange.fulfilled, (state, action) => {
          state.exchnagestatus = "succeeded";
          state.exchangeList = action.payload;
        })
        .addCase(fetchExchange.rejected, (state, action) => {
          state.exchnagestatus = "failed";
          state.error = action.error.message;
        })
       
    },
  });



export const selectExchangeList = (state) => state.exchangeData.exchangeList;

export const selectExchangeStatus = (state) => state.exchangeData?.exchnagestatus;
export const selectError = (state) => state.exchangeData.error;

export default exchangeSlice.reducer;