import React, { useEffect, useState } from "react";
import { Col, Row, Button, Dropdown } from "react-bootstrap";
import { FaSearch, FaGithub, FaCopy, FaYoutube } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { FacebookIcon, RedditIcon } from "react-share";
import axios from "axios";
import CoinNewsCard from "./CoinNewsCard";
function CoinProfileInfo({ data, styles }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [newsList, setNewsList] = useState();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/news/search/${encodeURIComponent(data?.name)}`
        );

        setNewsList(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    if (data?.name) {
      fetchNews();
    }
  }, [data]);

  const handleToggle = () => setDropdownOpen(!dropdownOpen);

  const handleCopyToggle = () => {
    if (copied) {
      setCopied(false);
    } else if (data?.name) {
      const textToCopy = data.name.toLowerCase();

      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
      });
    }
  };

  return (
    <div className="p-2 ">
      <div className={styles.InfoHeading}>
        <span> Info </span>
      </div>

      <Row className="g-1">
        <Col className="px-1" md={3}>
          <div>
            <Row className="mb-2">
              <Col className="my-auto text-left text-muted font-medium small ">
                Website
              </Col>
              <Col className="d-flex justify-content-end align-items-center gap-1 ">
                <Button
                  variant="light"
                  href={`${data?.links?.website}`}
                  target="_blank"
                  rel="nofollow noopener"
                  className="px-2.5 py-1.5 font-semibold text-sm rounded-lg d-inline-flex align-items-center justify-content-center"
                >
                  <div className="text-xs text-gray-900 hover:text-gray-900 focus:text-gray-900 dark:text-moon-50 dark:hover:text-moon-50 dark:focus:text-moon-50">
                    {`${data?.name}.org`}
                  </div>
                </Button>
                <Button
                  variant="light"
                  href={`${data?.whitepaper?.link}`}
                  target="_blank"
                  rel="nofollow noopener"
                  className="px-2.5 py-1.5 font-semibold text-sm rounded-lg d-inline-flex align-items-center justify-content-center"
                >
                  <div className="text-xs text-gray-900 hover:text-gray-900 focus:text-gray-900 dark:text-moon-50 dark:hover:text-moon-50 dark:focus:text-moon-50">
                    Whitepaper
                  </div>
                </Button>
              </Col>
            </Row>
            <hr className="my-1" />

            <Row className="d-flex justify-content-between align-items-center">
              <Col className="text-left text-gray-500 dark:text-moon-200 font-medium text-sm leading-5">
                Explorers
              </Col>

              <Col className="d-flex justify-content-end gap-1 align-items-center">
                <Button
                  variant="light"
                  href="https://mempool.space"
                  target="_blank"
                  rel="nofollow noopener"
                  className="px-2.5 py-1.5 font-semibold text-sm rounded-lg d-block mt-2 bg-gray-200 hover:bg-gray-300 dark:bg-moon-700 dark:hover:bg-moon-600 text-gray-900 dark:text-moon-50"
                >
                  Mempool
                </Button>

                <Dropdown show={dropdownOpen} onToggle={handleToggle}>
                  <Dropdown.Toggle
                    style={{ border: "none" }}
                    as="button"
                    variant="link"
                    className="px-2.5 py-2 font-semibold text-sm rounded-lg d-block mt-2 bg-gray-200 hover:bg-gray-300 dark:bg-moon-700 dark:hover:bg-moon-600 text-gray-900 dark:text-moon-50"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu
                    align="start"
                    className="max-h-48 overflow-auto bg-white mt-2 rounded-md shadow-lg w-56 p-2 z-index-2000"
                  >
                    {data?.links?.explorer?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        target="_blank"
                        href={item}
                        className="dark:text-moon-100 dark:hover:bg-moon-700 dark:hover:text-moon-50 hover:bg-gray-100 hover:text-gray-900 py-3 px-2 rounded-lg font-semibold text-sm text-gray-700"
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <hr className="my-1" />
            <Row className="py-2 d-flex align-items-center justify-content-between">
              <Col className="text-start text-muted fw-medium small lh-sm">
                Socials
              </Col>
              <Col className="ps-2 text-end text-dark fw-semibold small lh-sm gap-1">
                <>
                  <Button
                    href={`${data?.links?.facebook}`}
                    target="_blank"
                    variant="light"
                    rel="nofollow noopener"
                    className="d-inline-flex align-items-center justify-content-center rounded-circle p-0"
                    style={{
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                    }}
                  >
                    <FacebookIcon size={24} round={true} />
                  </Button>
                  <Button
                    href={`${data?.links?.reddit}`}
                    target="_blank"
                    variant="light"
                    rel="nofollow noopener"
                    className="d-inline-flex align-items-center justify-content-center rounded-circle p-0"
                    style={{
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                    }}
                  >
                    <RedditIcon size={24} round={true} />
                  </Button>
                  <Button
                    href={`${data?.links?.youtube}`}
                    target="_blank"
                    variant="light"
                    rel="nofollow noopener"
                    className="d-inline-flex align-items-center justify-content-center rounded-circle p-0"
                    style={{ width: "40px", height: "40px" }}
                  >
                    <FaYoutube size={24} color="red" />
                  </Button>
                </>
              </Col>
            </Row>
            <hr className="my-1" />
            <Row className="py-1">
              <Col className="d-flex align-items-center text-left text-muted">
                <span className="font-weight-medium text-sm">Search on</span>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  href={`https://twitter.com/search?q=$${data?.symbol}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="d-flex align-items-center px-2.5 py-1.5 rounded-lg"
                >
                  <FaSearch className="mr-1" />
                  <span className="font-weight-semibold text-sm">Twitter</span>
                </Button>
              </Col>
            </Row>
            <hr className="my-1" />
            <Row className="d-flex justify-content-between py-1">
              <Col className="my-auto text-left text-muted">
                <div>Source Code</div>
              </Col>

              <Col className="text-end">
                <Button
                  href={`${data?.links?.source_code}`}
                  target="_blank"
                  variant="light"
                  rel="nofollow noopener"
                  className="px-2.5 py-1.5 font-semibold text-sm rounded-lg d-inline-flex align-items-center justify-content-center"
                >
                  <FaGithub className="mr-2 m-1" />
                  Github
                </Button>
              </Col>
            </Row>
            <hr className="my-1" />
            <Row className="d-flex justify-content-between">
              <Col className="my-auto text-left text-muted">API ID</Col>
              <Col className="text-end">
                <Button
                  target="_blank"
                  variant="light"
                  rel="nofollow noopener"
                  className="px-2.5 py-1.5 font-semibold text-sm rounded-lg d-inline-flex align-items-center justify-content-center"
                >
                  <span className="mr-2 m-1 text-lowercase">{data?.name}</span>
                  {copied ? (
                    <GrStatusGood
                      onClick={handleCopyToggle}
                      style={{
                        fontSize: "1.2em",
                        color: "green",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FaCopy
                      onClick={handleCopyToggle}
                      style={{ cursor: "pointer", color: "#696969" }}
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={9}>
          <CoinNewsCard data={newsList} />
        </Col>
      </Row>
    </div>
  );
}

export default CoinProfileInfo;
