import React, { useEffect, useState } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaGlobe, FaTelegramPlane } from "react-icons/fa";
import Shimmer from "../../Components/shimmers/Shimmer";
import {
  fetchNewCoinData,
  selectGlobalDataStatus,
  selectnewCoinData,
  selectNewListingStatus,
} from "../../store/customer/globalDataSlice";
import homStyles from "./css/Home.module.css";

const NewsListingcoins = () => {
  const newCoinData = useSelector(selectnewCoinData);
  const status = useSelector(selectNewListingStatus);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNewCoinData());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        setCoins(newCoinData);
      } catch (error) {
        console.error("Error fetching coins:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoins();
  }, [newCoinData]);

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Shimmer width="90vh" height="80vh" borderRadius="0.8rem" />
      </Container>
    );
  }

  return (
    <Container fluid className="my-4 mx-4 ">
      <div className={`${homStyles.heading} mb-3 `}>New Listings</div>
      <Row className="mb-1 me-4">
        {coins?.map((coin) => (
          <Col key={coin.cpid} md={3} sm={6} xs={12} className="mb-4">
            <Card className="h-100 shadow-sm border-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card.Img
                  variant="top"
                  src={coin?.img?.url}
                  alt={`${coin.name} logo`}
                  className="img-fluid p-1"
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "contain",
                    borderRadius: "50%",
                  }}
                />
              </div>

              <Card.Body>
                <Card.Title className="mb-3 fw-bolder">
                  {coin?.name}
                  <span className="text-muted">({coin.symbol})</span>
                </Card.Title>
                <Card.Text className="mb-2 ">
                  <p className="mb-0">
                    <span className="fw-bold"> Rank : </span> {coin?.rank}
                  </p>
                  <p className="mb-0">
                    <span className="fw-bold"> Market Cap :</span> $
                    {coin?.close?.toFixed(2)}
                  </p>
                  <p className="mb-0">
                    <span className="fw-bold"> Current Price :</span> $
                    {coin?.close?.toFixed(2)}
                  </p>
                  <p className="mb-0">
                    <span className="fw-bold"> Volume : </span>{" "}
                    {coin?.volume?.toLocaleString()}
                  </p>
                  <p className="mb-0">
                    <span className="fw-bold"> Changes (%) :</span>{" "}
                    <span
                      className={`fw-bold ${
                        coin?.changes >= 0 ? "text-success" : "text-danger"
                      }`}
                    >
                      {Math.abs(coin?.changes)?.toFixed(2)}%
                    </span>
                  </p>
                </Card.Text>
                <div className="d-flex justify-content-end gap-3">
                  <a
                    href={coin.links.website[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                    style={{ fontSize: "1.2rem", cursor: "pointer" }}
                  >
                    <FaGlobe />
                  </a>
                  <a
                    href={coin.links.telegram[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-info"
                    style={{ fontSize: "1.2rem", cursor: "pointer" }}
                  >
                    <FaTelegramPlane />
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NewsListingcoins;
