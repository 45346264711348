import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/customer/Header";
import Footer from "../../Components/customer/Footer";
import DefaultTitle from "../../utils/DefaultTitle";
import '../../App.css';

function App() {
  const location = useLocation();

  const generateTitle = (path) => {
    if (path === "/") {
      return "IndexByte";
    }

    const segments = path.split("/").filter(Boolean);

    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
      return lastSegment;
    }

    return "Default Title";
  };

  const title = generateTitle(location.pathname);

  return (
    <>
      <Container className="Container">
        <DefaultTitle title={title} />
        <Header />
        <main>
            <Row>
              <Col>
                <Outlet />
              </Col>
            </Row>
        </main>
        <Footer />
      </Container>
    </>
  );
}

export default App;
