import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BiLike } from "react-icons/bi";
import { CiBookmark } from "react-icons/ci";
import { GoComment } from "react-icons/go";
import { IoShareSocial } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { flashAction } from "../../../store/customer/flashSlice";
import styles from "../css/NewsCard.module.css";
import moment from "moment";

function CoinNewsCard({ data }) {
  const [likes, setLikes] = useState(data?.likes?.length);
  const [isLiked, setIsLiked] = useState(false);
  const [userId, setUserId] = useState(null);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    setUserId(storedUserId);
    if (storedUserId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/news/${data?._id}/isLikeByUser/${storedUserId}`
        )
        .then((response) => {
          if (response.data.isLiked) {
            setIsLiked(true);
          }
        })
        .catch((error) => {
          console.error("Error checking if liked", error);
        });
    }
  }, [data?._id]);

  const handleLike = async () => {
    if (isSignin) {
      try {
        if (!isLiked) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${data?._id}/like/${userId}`
          );
          setLikes(likes + 1);
          setIsLiked(true);
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${data?._id}/unlike/${userId}`
          );
          setLikes(likes - 1);
          setIsLiked(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  return data?.map((news) => {
    console.log(news?.updatedAt, "nenene====================");

    const page = news?.type?.replace(/ /g, "-");
    const date = moment(data?.updatedAt).format("DD/MM/YYYY, h:mm A");

    return (
      <Card className={styles.card}>
        <Row className={styles.noGutters}>
          <Col md={4} xs={12} className={styles.positionRelative}>
            <div className={styles.textCenter}>
              <Link className={styles.bookmarkIcon}>
                <CiBookmark size={20} />
              </Link>
            </div>
            <Link
              to={`/${page}/${news?.slug}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card.Img
                src={news?.featuredImg?.url}
                alt={news?.featuredImg?.fileName}
                className={styles?.cardImg}
              />
            </Link>
          </Col>

          <Col md={8} xs={12}>
            <Card.Body className={styles.cardBody}>
              <Link
                to={`/${page}/${news?.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card.Title>{news?.heading}</Card.Title>
              </Link>
              <div className="mb-5">
                <div
                  className={`${styles.newDateTime} d-flex justify-content-between`}
                >
                  {news?.category} | {date}
                </div>
                <Card.Text className={styles.newsSummary}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${news?.summary
                        ?.split(" ")
                        .slice(0, 50)
                        .join(" ")}...`,
                    }}
                  />
                </Card.Text>
              </div>

              <div className={styles.bottomSection}>
                <div className={styles.iconSection}>
                  <span className={styles.iconWrapper}>
                    <svg width="0" height="0">
                      <defs>
                        <linearGradient
                          id="likeGradient"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: "#1e30f3", stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{ stopColor: "#e21e80", stopOpacity: 1 }}
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <BiLike
                      size={16}
                      onClick={handleLike}
                      className={`${styles.likeIcon} ${
                        isLiked ? styles.liked : styles.notLiked
                      }`}
                    />{" "}
                    {likes}
                  </span>
                  <span className={styles.iconWrapper}>
                    <GoComment className={styles.notLiked} size={16} />
                    {news?.comments}
                  </span>
                  <span className={styles.iconWrapper}>
                    <IoShareSocial size={16} />
                  </span>
                </div>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  });
}

export default CoinNewsCard;
