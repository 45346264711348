import { React, memo } from "react";
import { Col, Container, Row } from "react-bootstrap"
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import FakeUpdation from "../shimmers/FakeUpdation";
import { formatNumber } from "../../utils/common/formateNumber";



const CoinItems = memo(({ item }) => {
    const renderPercentageChange = (value) => {
      if (value > 0) {
        return (
          <span className="text-success d-flex align-items-center">
            <FaCaretUp className="me-1" /> {value.toFixed(2)}%
          </span>
        );
      } else {
        return (
          <span className="text-danger d-flex align-items-center">
            <FaCaretDown className="me-1" /> {Math.abs(value).toFixed(2)}%
          </span>
        );
      }
    };
  
    return (
      <Row className="text-start py-2 border-top border-bottom justify-content-center align-items-start mx-3">
        <Col className="d-none d-md-block" md={1}>{item.rank}</Col>
        <Col xs={3} md={2}>
          <img src={item?.img?.url} alt="" width={24} className="me-1" />
          {item?.symbol}
        </Col>
        <Col xs={3} md={3}><FakeUpdation price={item?.quotes.USD.price}/></Col>
        {/* <Col xs={3} md={1}>
          {renderPercentageChange(item?.quotes.USD.percent_change_1h)}
        </Col> */}
        <Col xs={3} md={3}>
          {renderPercentageChange(item?.quotes.USD.percent_change_24h)}
        </Col>
        {/* <Col className="d-none d-md-block" md={1}>
          {renderPercentageChange(item?.quotes.USD.percent_change_7d)}
        </Col>
        <Col className="d-none d-md-block" md={2}>
          ${formatNumber(item?.quotes.USD.volume_24h)}
        </Col> */}
        <Col className="d-none d-md-block" md={3}>
          ${formatNumber(item?.quotes.USD.market_cap)}
        </Col>
      </Row>
    );
  });

const GainerLoser=({head,data,styles, count=100})=>{
    return(
        <Container className={styles?.coinTableText}>
          <h3>{head}</h3>
            <Row className="text-start fw-bold border-top border-bottom py-3 align-items-start mx-3">
          <Col md={1}>#</Col> 
          <Col xs={3} md={2}>Coin</Col>
          <Col xs={3} md={3}>Price</Col>
          {/* <Col xs={3} md={1} className="fs-9 fs-md-5">1h</Col> */}
          <Col xs={3} md={3}>24h</Col>
          {/* <Col md={1} className="d-none d-md-block fs-md-5">7d</Col>
          <Col className="d-none d-md-block fs-md-5" md={2}>24h Volume</Col> */}
          <Col className="d-none d-md-block fs-md-5" md={3}>Market Cap</Col>
        </Row>
        {data?.slice(0, count).map((item) => (
          <CoinItems key={item.rank} item={item} />
        ))}
        </Container>
    )
}
export default GainerLoser;


