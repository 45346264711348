import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopGainers, fetchTopLosers, selectTopGainers, selectTopLosers } from "../../store/customer/gainerLooserSlice";
import GainerLoser from "../../Components/customer/GainerLoser";
import styles from "./css/Home.module.css";
import { selectGlobalDataStatus } from "../../store/customer/globalDataSlice";


const TopGainerLoser=()=>{
    const dispatch=useDispatch();
    const topGainers = useSelector(selectTopGainers);
  const topLoosers = useSelector(selectTopLosers);
  const status = useSelector(selectGlobalDataStatus);
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTopGainers());
      dispatch(fetchTopLosers());
    }
  }, [status, dispatch]);
    return(
        <Container fluid>
            <Row>
                <Col>Top Crypto Gainers and Losers</Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}><GainerLoser head={"🚀Top Gainers"} data={topGainers} styles={styles} ></GainerLoser></Col>
                <Col xs={12} lg={6}><GainerLoser head={"🚨Top Looser"} data={topLoosers} styles={styles} ></GainerLoser></Col>
            </Row>
        </Container>
    )
}

export default TopGainerLoser;


