import React, { useEffect, useState } from "react";
import TredingViewChart from "../../Components/customer/TredingViewChart";

import { Badge, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "../customer/css/CoinProfile.module.css";
import CoinProfileAbout from "../../Components/customer/coin/CoinProfileAbout";
import CoinProfileInfo from "../../Components/customer/coin/CoinProfileInfo";
import CoinProfileMarketDetails from "../../Components/customer/coin/CoinProfileMarketDetails";

function CoinProfile() {
  const { id } = useParams();

  const [coinData, setCoinData] = useState(null);

  useEffect(() => {
    const fetchCoin = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/global-data/getCoins/${id}`
        );
        setCoinData(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCoin();
  }, [id]);

  if (!setCoinData) return <p>Loading...</p>;

  return (
    <div className={` ${styles?.coinProfileTop}`}>
      <div>
        <CoinProfileMarketDetails coinData={coinData} styles={styles} />
      </div>

      <div className="mb-3">
        {coinData?.symbol !== undefined ? (
          <TredingViewChart symbol={`${coinData?.symbol}USD`} styles={styles} />
        ) : (
          <div className={styles.chartUndefined}>
            <span>
              "The latest updates are coming your way... Get ready for what’s
              next!"
            </span>
          </div>
        )}
      </div>

      <div>
        <CoinProfileInfo data={coinData} styles={styles} />
      </div>
    </div>
  );
}

export default CoinProfile;
