import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./css/Home.module.css";
import Hero from "../../Components/customer/Hero";
import CryptoCurrency from "../../Components/customer/CryptoCurrency";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTopbarData,
  fetchNewCoinData,
  selectTopbar,
  selectnewCoinData,
  selectGlobalDataStatus,
  selectNewListingStatus,
} from "../../store/customer/globalDataSlice";

import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import Shimmer from "../../Components/shimmers/Shimmer";

import TopNews from "../../Components/customer/TopNews";
import {
  fetchNews,
  SelectNews,
  selectNewsList,
} from "../../store/customer/newsFetchSlice";
import Tickers from "../../Components/customer/Tickers";
import ListingDetails from "../../Components/customer/ListingDetails";
import {
  fetchTopGainers,
  fetchTopLosers,
  selectGainerLooserStatus,
  selectTopGainers,
  selectTopLosers,
} from "../../store/customer/gainerLooserSlice";
import Ads from "../../Components/customer/ads/Ads";
const Home = () => {
  const dispatch = useDispatch();

  const topbarData = useSelector(selectTopbar);
  const newCoinData = useSelector(selectnewCoinData);
  const globalStatus = useSelector(selectGlobalDataStatus);
  const { newsList, newsStatus } = useSelector(SelectNews);
  const topGainers = useSelector(selectTopGainers);
  const topLoosers = useSelector(selectTopLosers);
  const gainerLooserStatus = useSelector(selectGainerLooserStatus);
  const newListingStatus = useSelector(selectNewListingStatus);

  useEffect(() => {
    if (globalStatus === "idle") {
      dispatch(fetchTopbarData());
    }
    if (newListingStatus === "idle") {
      dispatch(fetchNewCoinData());
    }
    if (newsStatus === "idle") {
      dispatch(fetchNews({ type: "market news", page: 1, limit: 5 }));
    }
    if (gainerLooserStatus === "idle") {
      dispatch(fetchTopGainers());
      dispatch(fetchTopLosers());
    }
  }, [
    globalStatus,
    gainerLooserStatus,
    newListingStatus,
    newsStatus,
    dispatch,
  ]);

  // if (status === "failed") {
  //   return <div>Error: {error}</div>;
  // }

  if (!topbarData) {
    return <Shimmer width="90rem" height="28rem" borderRadius="1rem"></Shimmer>;
  }

  const formatNumber = (num) => {
    if (Math.abs(num) >= 1.0e12) {
      return (num / 1.0e12).toFixed(3) + "T";
    } else if (Math.abs(num) >= 1.0e9) {
      return (num / 1.0e9).toFixed(3) + "B";
    } else if (Math.abs(num) >= 1.0e6) {
      return (num / 1.0e6).toFixed(3) + "M";
    } else {
      return num?.toLocaleString();
    }
  };

  const { market_cap_usd, market_cap_change_24h } = topbarData;

  return (
    <>
      <Container fluid>
        <Row>
          <Hero styles={styles} />
        </Row>
        <Row>
          <Tickers styles={styles} />
        </Row>

        <Row className="mt-5">
          <Col className="mx-3">
            <div className={`${styles?.heading} ms-3`}>
              Cryptocurrency Prices by Market Cap
            </div>
            <div className={`ms-3`}>
              The global crypto market cap is{" "}
              {globalStatus === "loading" ? (
                <Shimmer width="1rem" height="1rem" borderRadius="0.1rem" />
              ) : (
                formatNumber(market_cap_usd)
              )}
              , a{" "}
              <span
                className={
                  market_cap_change_24h >= 0 ? "text-success" : "text-danger"
                }
              >
                {market_cap_change_24h >= 0 ? (
                  <>
                    {" "}
                    <FaArrowUp /> increase{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <FaArrowDown /> decrease{" "}
                  </>
                )}
                {market_cap_change_24h}%
              </span>{" "}
              over the last day.
            </div>
          </Col>
        </Row>
        <Row className="d-flex mt-5 mx-sm-3 g-0 align-items-start ">
          <Col xs={12} md={4} className="px-sm-2 ">
            {globalStatus === "loading" ? (
              <Shimmer width="5rem" height="5rem" borderRadius="1rem" />
            ) : (
              <div>
                <CryptoCurrency
                  data={newCoinData}
                  Name="New Listings"
                  to={"/newListings"}
                  styles={styles}
                />
                <CryptoCurrency
                  data={topGainers}
                  styles={styles}
                  Name="Top Gainers"
                  to={"/top-gainer-looser"}
                />
                <CryptoCurrency
                  data={topLoosers}
                  styles={styles}
                  Name="Top Losers"
                  to={"/top-gainer-looser"}
                />
              </div>
            )}
            <div className="px-sm-2 ">
              <Ads type="banner" />
            </div>
          </Col>

          <Col xs={12} md={8} className="px-sm-2 ">
            {newsStatus === "loading" ? (
              <Shimmer width="5rem" height="5rem" borderRadius="1rem" />
            ) : (
              <>
                <TopNews styles={styles} data={newsList} col={2} maxNews={4} />
                <ListingDetails styles={styles} count={100} />
              </>
            )}
          </Col>
        </Row>

        {/* <Row className="mt-5">
          <Col>
            <ListingDetails />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default Home;
