import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  formateCoinPrice,
  formatNumber,
} from "../../../utils/common/formateNumber";
function CoinProfileMarketDetails({ coinData, styles }) {
  const price = coinData?.quotes?.USD?.price;
  const percentageChange = coinData?.quotes?.USD?.percent_change_24h;
  let lowValue = price;
  let highValue = price;
  if (percentageChange > 0) {
    highValue = price + price * (percentageChange / 100);
  } else if (percentageChange < 0) {
    lowValue = price + price * (percentageChange / 100);
  }
  const formattedLowValue = lowValue?.toFixed(2);
  const formattedHighValue = highValue?.toFixed(2);

  return (
    <>
      <Row className="align-items-center py-4 ">
        <Col className="d-flex" md={4}>
          <div className="d-flex align-items-center gap-2 d-none d-md-flex">
            <Image
              src={coinData?.img?.url}
              alt="coin"
              className={styles.coinIcon}
            />
            <div className="d-flex align-items-center ">
              <span className={styles.coinName}>{coinData?.name}</span>
              <span className={styles.coinSymbol}>{coinData?.symbol}</span>
              <span className={styles.coinRank}>#{coinData?.rank}</span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <span className={styles.coinPrice}>
                ${formateCoinPrice(coinData?.quotes?.USD?.price)}
              </span>
              <span
                className={
                  coinData?.quotes?.USD?.percent_change_24h > 0
                    ? "text-success"
                    : "text-danger"
                }
              >
                {coinData?.quotes?.USD?.percent_change_24h > 0 ? (
                  <FaCaretUp />
                ) : (
                  <FaCaretDown />
                )}
                {Math.abs(coinData?.quotes?.USD?.percent_change_24h).toFixed(1)}
                %
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2 d-md-none">
            <Image
              src={coinData?.img?.url}
              alt="coin"
              className={styles.coinIcon}
            />
            <div className="">
              <span className={styles.coinName}>{coinData?.name}</span>
              <span className={styles.coinSymbol}>{coinData?.symbol}</span>
              <span className={styles.coinRank}>#{coinData?.rank}</span>

              <div className="d-flex">
                <span className={styles.coinPrice}>
                  ${formateCoinPrice(coinData?.quotes?.USD?.price)}
                </span>
                <span
                  className={
                    coinData?.quotes?.USD?.percent_change_24h > 0
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {coinData?.quotes?.USD?.percent_change_24h > 0 ? (
                    <FaCaretUp />
                  ) : (
                    <FaCaretDown />
                  )}
                  {Math.abs(coinData?.quotes?.USD?.percent_change_24h).toFixed(
                    1
                  )}
                  %
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end px-1 w-100 d-md-none">
            <div className="text-end">
              <span className={`${styles.coinDetailsHead} me-3`}>
                Market Cap
              </span>
              <span className={styles.coinDetailsBody}>
                ${formatNumber(coinData?.quotes?.USD?.market_cap?.toFixed(0))}
                <span
                  className={
                    coinData?.quotes?.USD?.market_cap_change_24h > 0
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {coinData?.quotes?.USD?.market_cap_change_24h > 0 ? (
                    <FaCaretUp />
                  ) : (
                    <FaCaretDown />
                  )}
                  {Math.abs(
                    coinData?.quotes?.USD?.market_cap_change_24h
                  ).toFixed(1)}
                  %
                </span>
              </span>
            </div>
          </div>
        </Col>

        <Col className="d-md-none ">
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <div
                className={styles.progressBar}
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className={styles.labels}>
              <span className="text-danger"> ${formattedLowValue} </span>
              <span>
                24h Low <span className="fs-12">/</span> High{" "}
              </span>
              <span className="text-success"> ${formattedHighValue}</span>
            </div>
          </div>
        </Col>

        <Col className="d-none d-md-block ">
          <span className={styles.coinDetailsHead}>Market Cap</span>
          <span className={styles.coinDetailsBody}>
            <span className={styles.coinDetailsBody}>
              ${formatNumber(coinData?.quotes?.USD?.market_cap?.toFixed(0))}
              <span
                className={
                  coinData?.quotes?.USD?.market_cap_change_24h > 0
                    ? "text-success"
                    : "text-danger"
                }
              >
                {coinData?.quotes?.USD?.market_cap_change_24h > 0 ? (
                  <FaCaretUp />
                ) : (
                  <FaCaretDown />
                )}
                {Math.abs(coinData?.quotes?.USD?.market_cap_change_24h).toFixed(
                  2
                )}
                %
              </span>
            </span>
          </span>
        </Col>

        <Col className="d-none d-md-block">
          <span className={styles.coinDetailsHead}>Low - High(24H)</span>
          <span className={styles.coinDetailsBody}>
            <span className="text-danger"> ${formattedLowValue} </span>-
            <span className="text-success"> ${formattedHighValue}</span>
          </span>
        </Col>

        <Col className="d-none d-md-block">
          <span className={styles.coinDetailsHead}>Vol(24H)</span>
          <span className={styles.coinDetailsBody}>
            ${formatNumber(coinData?.quotes?.USD?.volume_24h?.toFixed(0))}
          </span>
        </Col>

        <Col className="d-none d-md-block">
          <span className={styles.coinDetailsHead}>
            Current Supply - Total Supply
          </span>
          <span className={styles.coinDetailsBody}>
            {formatNumber(coinData?.total_supply?.toFixed(2))} -
            {formatNumber(coinData?.max_supply?.toFixed(2) || "∞")}
          </span>
        </Col>

        <div className="d-md-none px-3">
          <div className="d-flex justify-content-between ">
            <div>
              <span className={`${styles.coinDetailsHead} `}>Vol(24H)</span>
              <span className={styles.coinDetailsBody}>
                ${formatNumber(coinData?.quotes?.USD?.volume_24h?.toFixed(0))}
              </span>
            </div>

            <div className="text-center">
              <span className={`${styles.coinDetailsHead}  `}>
                Current Supply
              </span>
              <span className={styles.coinDetailsBody}>
                {formatNumber(coinData?.total_supply?.toFixed(2))}
              </span>
            </div>
            <div className="text-center">
              <span className={`${styles.coinDetailsHead} `}>Total Supply</span>
              <span className={styles.coinDetailsBody}>
                {formatNumber(coinData?.max_supply?.toFixed(2) || "∞")}
              </span>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

export default CoinProfileMarketDetails;
