import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import axios from "axios";
import styles from "./css/NewsDetail.module.css";
import tickerStyles from "./css/Home.module.css";
import { BiLike } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { flashAction } from "../../store/customer/flashSlice";
import DescriptionManipulate from "../../Components/customer/DescriptionManipulate";
import DynamicBreadcrumb from "../../Components/customer/BreadCrumb";
import Shimmer from "../../Components/shimmers/Shimmer";
import moment from "moment";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Tickers from "../../Components/customer/Tickers";
import { fetchNews, SelectNews } from "../../store/customer/newsFetchSlice";
import { GoComment } from "react-icons/go";
import { IoShareSocial } from "react-icons/io5";

const NewsDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [news, setNews] = useState(null);
  const [likes, setLikes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [userId, setUserId] = useState(null);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const { newsList, page } = useSelector(SelectNews);
  const location = useLocation();
  const shareUrl = `${process.env.REACT_APP_MAIN_ORIGIN + location.pathname}`;

  const tochoosePage = ["market news", "market reports", "analytics", "articles", "market analysis"];

  const getRandomElement = (excludeType) => {
    const randomIndex = Math.floor(Math.random() * tochoosePage.length);
    return tochoosePage[randomIndex];
  };
  
  useEffect(() => {
    const fetchNewsData = async () => {
      const randomType = getRandomElement(news.type);
      
      // Dispatching the second call with a different type
      dispatch(
        fetchNews({
          type: randomType,
          page: 1,
          limit: 5,
        })
      );
   
    };
  
    if(news){
    fetchNewsData();
  }
  }, [dispatch,news]);
  

  useEffect(() => {
    const fetchNews = async () => {
      const storedUserId = localStorage.getItem("userId");
      setUserId(storedUserId);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/news/get/${slug}`
        );
        setNews(response.data);
        setLikes(response.data.likes.length);
        if (storedUserId) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/api/news/${response.data._id}/isLikeByUser/${storedUserId}`
            )
            .then((response) => {
              if (response.data.isLiked) {
                setIsLiked(true);
              }
            })
            .catch((error) => {
              console.error("Error checking if liked", error);
            });
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [slug]);

  const handleLike = async () => {
    if (isSignin) {
      try {
        if (!isLiked) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${news._id}/like/${userId}`
          );
          setLikes(likes + 1);
          setIsLiked(true);
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news/${news._id}/unlike/${userId}`
          );
          setLikes(likes - 1);
          setIsLiked(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  if (loading)
    return <Shimmer width="100rem" height="25rem" borderRadius="1rem" />;
  if (!news) return <p>No news found.</p>;
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const date = new Date(news.updatedAt).toLocaleDateString("en-GB", options);


  return (
    <Container fluid className={`${styles.newsContainer}`}>
      <Row>
          <Tickers styles={tickerStyles} />
        </Row>
      <Row className="mx-3 d-none d-md-flex">
        <DynamicBreadcrumb />
      </Row>
      <Row className="mx-3 mt-3 ">
        <Col lg={9} className=" justify-content-between">
          <div className={styles.newsHeader}>
            <h1 className={styles.newsHeading}>{news.heading}</h1>
            <p className={styles.newsInfo}>
              {news.category.charAt(0).toUpperCase() + news.category.slice(1)} |{" "}
              {date}
            </p>
          </div>

          <div className={styles.iconContainer}>
            <div className="mx-2">
              <Link
                to={`https://X.com/intent/tweet?url=${shareUrl}`}
                target="_blank"
              >
                <XIcon size={30} round={true} />
              </Link>
            </div>
            <div className="mx-2">
              <Link
                to={`https://telegram.me/share/url?url=${shareUrl}`}
                target="_blank"
              >
                <TelegramIcon size={30} round={true} />
              </Link>
            </div>
            <div className="mx-2">
              <Link
                to={`https://api.whatsapp.com/send?text=${shareUrl}`}
                target="_blank"
              >
                <WhatsappIcon size={30} round={true} />
              </Link>
            </div>
            <div className="mx-2">
              <Link
                to={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                target="_blank"
              >
                <FacebookIcon size={30} round={true} />
              </Link>
            </div>
            <div className="mx-2">
              <Link
                to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
                target="_blank"
              >
                <LinkedinIcon size={30} round={true} />
              </Link>
            </div>
            <div className="mx-2">
              <Link
                to={`https://reddit.com/submit?url=${shareUrl}`}
                target="_blank"
              >
                <RedditIcon size={30} round={true} />
              </Link>
            </div>
          </div>
          <div className={`${styles.newsContent} text-body`}>{news.summary}</div>

          <Image
            src={news.featuredImg.url}
            alt={news.featuredImg.fileName}
            fluid
            className={styles.newsImage}
          />

          {/* <div className={styles.authorSection}>
            <Image
              src={news?.author?.imgurl}
              className={styles.authorImage}
              roundedCircle
            />
            <h4 className={styles.authorName}>
              Written by {news?.author?.name}
            </h4>
          </div> */}

          <div className={styles.descriptionSection}>
            <DescriptionManipulate content={news.description} styles={styles} />
          </div>

          <div className={styles.buttonGroup}>
            <div className="d-flex">
              <span className={styles.iconContainer}>
                <BiLike
                  size={18}
                  onClick={handleLike}
                  className={`${styles.likeIcon} ${
                    isLiked ? styles.liked : styles.notLiked
                  }`}
                />
                {likes}
              </span>
            </div>
          </div>
          <Row className={styles.commentSection}>
            <NewsComments news={news} userId={userId} />
          </Row>
        </Col>
        <Col lg={3}>

{/* Related News  */}


        <div className={styles.sideNews}>Related News</div>
      {newsList.map((newsItem) => (
                  
                  <Card className={styles.acustomCard}>
                    <Link
                      to={`/${page}/${newsItem?.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Card.Img
                        variant="top"
                        src={newsItem.featuredImg?.url}
                        alt={newsItem.featuredImg.fileName}
                        className={styles.aresponsiveImage}
                      />
                    </Link>
                    <Card.Body>
                      <Link
                        to={`/${page}/${newsItem?.slug}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Card.Title className={styles.sideNewsHeading}>{newsItem.heading}</Card.Title>
                      </Link>
        
                      <Card.Text>
                        <div
                        className={styles.sideNewsSummary}
                          dangerouslySetInnerHTML={{
                            __html: `${newsItem.summary
                              ?.split(" ")
                              .slice(0, 20)
                              .join(" ")}...`,
                          }}
                        />
                      </Card.Text>
                      <Card.Text>
                        <small className="text-muted">{newsItem?.author?.name}</small>
                        <br />
                        <small>
                          {newsItem.category} | {new Date(news.updatedAt).toLocaleDateString("en-GB", options)}
                        </small>
                      </Card.Text>
                      <div className={styles.iconSection}>
                        <span className={styles.iconWrapper}>
                          <BiLike
                            size={16}
                            onClick={handleLike}
                            className={`${styles.likeIcon} ${
                              isLiked ? styles.liked : styles.notLiked
                            }`}
                          />{" "}
                          {likes}
                        </span>
                        <span className={styles.iconWrapper}>
                          <GoComment className={styles.notLiked} size={16} />{" "}
                          {news?.comments}
                        </span>
                        <span>
                          <IoShareSocial size={16} /> {news?.shares}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                
                ))}
        </Col>
      </Row>
      <Row>
        <Col sm={9}>
          
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

const NewsComments = ({ news, userId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyInputId, setReplyInputId] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const [newLike, setNewLike] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchComments = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news/getComments?newsId=${news._id}`
      );
      console.log(response.data);
      setComments(response.data.data);
    };
    if (news) {
      fetchComments();
    }
  }, [news, newLike]);

  const handleReplyLike = async (commentId, replyId) => {
    if (!userId) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/news/comment/likeReply`,
      {
        commentId,
        replyId,
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setNewLike(Math.random);

    console.log(`Liked reply with ID: ${replyId}`);
  };

  const toggleReplyInput = (commentId) => {
    setReplyInputId(replyInputId === commentId ? null : commentId);
  };

  const handleAddReply = async (commentId) => {
    if (!userId) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/news/comment/addReply`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ commentId, content: replyContent, userId }),
      }
    );
    setNewLike(Math.random);
    setReplyContent("");
    setReplyInputId(null);
  };

  const handleCommentLike = async (commentId) => {
    if (!userId) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/news/likeComment`,
      {
        commentId,
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setNewLike(Math.random);
  };

  const handleAddComment = async () => {
    if (!userId) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    if (!newComment.trim()) return;

    // Add the new comment to backend
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/news/addComment`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newsId: news._id, content: newComment, userId }),
      }
    );

    if (response.ok) {
      const addedComment = await response.json();
      setNewLike(Math.random);
      setNewComment("");
    }
  };

  return (
    <>
      <h3>Comments</h3>

      <div className={styles.addComment}>
        <textarea
          placeholder="Add a public comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        ></textarea>
        <button onClick={handleAddComment}>Comment</button>
      </div>

      {/* Displaying List of Comments */}
      <div className={styles.commentList}>
        {comments?.map((comment) => (
          <div key={comment._id} className={styles.comment}>
            <div className={styles.userAvatar}>
              <img src={comment.userId?.profileImg} alt="" />
            </div>
            <div className={styles.commentContent}>
              <div className={styles.username}>@{comment.userId?.userName}</div>
              <div className={styles.text}>{comment.content}</div>
              <div className={styles.commentActions}>
                <span onClick={() => handleCommentLike(comment._id)}>
                  <BiLike
                    size={12}
                    className={`${styles.likeIcon} ${
                      comment.likes.includes(userId)
                        ? styles.liked
                        : styles.notLiked
                    }`}
                  />
                  {comment.likes.length}
                </span>{" "}
                ·
                <span
                  onClick={() => toggleReplyInput(comment._id)}
                  style={{ cursor: "pointer" }}
                >
                  Reply {comment.replies.length}{" "}
                  {replyInputId === comment._id ? (
                    <FaCaretUp />
                  ) : (
                    <FaCaretDown />
                  )}
                </span>{" "}
                ·<span>{moment(comment.createdAt).fromNow()}</span>
              </div>

              {/* Reply Input Section */}
              {replyInputId === comment._id && (
                <div className={styles.replyInput}>
                  <textarea
                    placeholder="Add a reply..."
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                  ></textarea>
                  <button onClick={() => handleAddReply(comment._id)}>
                    Reply
                  </button>
                </div>
              )}

              {/* Display Replies */}
              <div className={styles.replyList}>
                {comment.replies &&
                  replyInputId === comment._id &&
                  comment.replies.map((reply) => (
                    <div key={reply._id} className={styles.reply}>
                      <div className={styles.userAvatar}>
                        <img
                          src="https://www.gfd.de/images/GFD_Luftfahrt.jpg"
                          alt=""
                        />
                      </div>
                      <div className={styles.commentContent}>
                        <div className={styles.username}>
                          {reply.userId?.username}
                        </div>
                        <div className={styles.text}>{reply.content}</div>
                        <div className={styles.commentActions}>
                          <span
                            onClick={() =>
                              handleReplyLike(comment._id, reply._id)
                            }
                          >
                            <BiLike
                              size={12}
                              className={`${styles.likeIcon} ${
                                reply.likes?.includes(userId)
                                  ? styles.liked
                                  : styles.notLiked
                              }`}
                            />
                            {reply.likes?.length}
                            {}
                          </span>{" "}
                          ·<span>{moment(reply.replyAt).fromNow()}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewsDetail;
