import React from 'react';

const DescriptionManipulate = ({ content, styles }) => {
  const replaceHashtagsAndMentionsWithLinks = (htmlString) => {
    
    const hashtagRegex = /#(\w+)/g;
    const mentionRegex = /@(\w+)/g;
    const linksReagex = /<a\s+(href="[^"]*")/g;

    const updatedContentWithLinks = htmlString.replace(
      linksReagex,
      '<a target="_blank" rel="noopener noreferrer" $1'
    );

    const updatedWithHashtags = updatedContentWithLinks.replace(hashtagRegex, (match, tag) => {
      const url = `/news/tags/${tag}`;
      return `<a href="${url}" class="hashtag-link">${match}</a>`;
    });

    return updatedWithHashtags.replace(mentionRegex, (match, id) => {
      const profileUrl = `/profile/${id}`;
      return `<a href="${profileUrl}" class="mention-link">${match}</a>`;
    });
  };

  const updatedContent = replaceHashtagsAndMentionsWithLinks(content);

  return (
    <div className={`${styles.descriptionContent} text-body`} dangerouslySetInnerHTML={{ __html: updatedContent }} />
  );
};

export default DescriptionManipulate;
