import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTicker, selectTickerList, selectTickerStatus } from "../../store/customer/coinsSlice";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import { formateCoinPrice } from "../../utils/common/formateNumber";

const Tickers = ({ styles }) => {
  const dispatch = useDispatch();
  const tickerData = useSelector(selectTickerList);
  let status = useSelector(selectTickerStatus);

  const [prices, setPrices] = useState([]);
  const previousData = useRef([]);


  const cachedTickers = useMemo(() => {
    return tickerData?.slice(0, 25).map((item) => ({
      symbol: item?.symbol,
      img: item?.img?.url,
      price: formateCoinPrice(item?.quotes.USD.price),
    }));
  }, [tickerData]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTicker());
    }
    if (status === "succeeded") {
      setPrices(tickerData.map((item) => formateCoinPrice(item.quotes.USD.price)));
      previousData.current = tickerData; 
    }

    const intervalId = setInterval(() => {
      if (status === "succeeded") {
        dispatch(fetchTicker());
      }
    }, 200000);

    return () => clearInterval(intervalId);
  }, [status, dispatch, tickerData]);

  if (status === "loading") {
    return (
      <div className={`${styles.tickerContainer}`}>
        <Marquee pauseOnHover={true} speed={40} direction="left"></Marquee>
      </div>
    );
  }

  if (status === "failed") {
    return <div>Error loading ticker data</div>;
  }

  return (
    <div className={`${styles.tickerContainer}`}>
      <Marquee pauseOnHover={true} speed={40} direction="left">
        {cachedTickers.map((item, index) => (
          <div key={index} className={`${styles.tickerItem}`}>
            {/* Static part (Logo and Symbol) */}
            <img src={item.img} width={20} alt={item.symbol} /> {item.symbol}:

            {/* Only animate the price change */}
            <motion.span
              key={prices[index]} // Animate only price
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              className="ticker-value"
            >
              {prices[index]}
            </motion.span>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Tickers;
