import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialReddit,
  SlSocialTwitter,
} from "react-icons/sl";
import { PiTelegramLogoLight } from "react-icons/pi";

const Footer = () => {
  return (
    <>
      <div className="footer-linebg mt-5"></div>
      <footer className="footer text-dark">
        <Container fluid className="mt-5">
          <Row>
            {/* <Col xs={1}></Col> */}
            <Col xs={4} xl={3} className="mb-1  mx-xl-5">
              <img
                src={process.env.REACT_APP_FOOTER_LOGO}
                alt="IndexByte Logo"
                className="footer-logo"
              />
              <p className="footer-text">
                {process.env.REACT_APP_BRAND_NAME}, USA
              </p>
              <div className=" d-flex gap-3">
                <Link to="#" className="text-dark footer-icon">
                  <SlSocialTwitter />
                </Link>
                <Link to="#" className="text-dark footer-icon">
                  <SlSocialFacebook />
                </Link>
                <Link to="#" className="text-dark footer-icon">
                  <SlSocialReddit />
                </Link>
                <Link to="#" className="text-dark footer-icon">
                  <SlSocialInstagram />
                </Link>
                <Link to="#" className="text-dark footer-icon">
                  <PiTelegramLogoLight size={20} />
                </Link>
              </div>
            </Col>
            <Col xs={3} sm={2} lg={0}></Col>
            <Col xs={4} sm={2} className="mb-1">
              <p className="footer-heading">Company</p>
              <Nav className="flex-column footer-links">
                <Nav.Link as={Link} to="/" className="text-dark footer-link">
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  className="text-dark footer-link"
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  className="text-dark footer-link"
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/terms"
                  className="text-dark footer-link"
                >
                  Terms of Services
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/privacy"
                  className="text-dark footer-link"
                >
                  Privacy Policy
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs={7} sm={2} className="mb-1">
              <p className="footer-heading">Market</p>
              <Nav className="flex-column footer-links">
                <Nav.Link
                  as={Link}
                  to="/market-news"
                  className="text-dark footer-link"
                >
                  Market News
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/market-analysis"
                  className="text-dark footer-link"
                >
                  Market Analysis
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/market-report"
                  className="text-dark footer-link"
                >
                  Market Report
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/articles"
                  className="text-dark footer-link"
                >
                  Articles
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/top100"
                  className="text-dark footer-link"
                >
                  Top 100
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs={5} sm={2} className="mb-1">
              <p className="footer-heading">Information</p>
              <Nav className="flex-column footer-links">
                <Nav.Link
                  as={Link}
                  to="/free-crypto"
                  className="text-dark footer-link"
                >
                  Free Crypto
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/new-listings"
                  className="text-dark footer-link"
                >
                  New Listings
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/upcoming-projects"
                  className="text-dark footer-link"
                >
                  Upcoming Projects
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/business-enquiry"
                  className="text-dark footer-link"
                >
                  Business Enquiry
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
          <Row className="mx-5 mt-5 ">
            <Col className=" mt-2">
              <p className="footer-copy">
                &copy; {new Date().getFullYear()}{" "}
                {process.env.REACT_APP_BRAND_NAME}. All rights reserved.
              </p>
            </Col>
            <Col className="text-end mt-2">
              <p className="footer-dev ">
                Developed by{" "}
                <Link
                  className="dreamzsop"
                  to={"https://www.dreamzsop.com/"}
                  target="/blank"
                >
                  Dreamzsop
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
