import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { flashAction } from "../../store/customer/flashSlice";

const ResetPassword = () => {
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleOtpVerify = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/verify-otp`,
        { email, otp }
      );

      if (response.status === 200) {
        setIsOtpVerified(true);
      }
    } catch (error) {
      console.error("OTP verification failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSavePassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`,
        { email, newPassword }
      );

      if (response.status === 200) {
        dispatch(flashAction.addFlash("Your password change successfully"));
        navigate("/signin");
      }
    } catch (error) {
      console.error("Failed to save password", error);
    }
  };

  return (
    <div>
      <h2>Password Reset</h2>

      <div>
        <label>Enter OTP:</label>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          disabled={isOtpVerified || isLoading}
        />
        <button onClick={handleOtpVerify} disabled={isOtpVerified || isLoading}>
          {isLoading ? "Verifying..." : "Verify OTP"}
        </button>
      </div>

      <div>
        <label>Enter New Password:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={!isOtpVerified}
        />
      </div>

      <button onClick={handleSavePassword} disabled={!isOtpVerified}>
        Save Password
      </button>
    </div>
  );
};

export default ResetPassword;
