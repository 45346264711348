import React from 'react';
import { Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const LowerNavbar = ({data}) => {
    const {isOpen,handleLinkClick}=data
  return (
    <Container fluid className={`custNavbar`}>
      <Row className="d-flex align-items-center">
        <Navbar
          bg="light"
          expand="lg"
          expanded={isOpen}
          className="header-navbar"
        >
          {/* <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={`ms-auto handbugger ${isOpen ? "active" : ""}`}
            onClick={handleToggle}
          >
            {isOpen ? <FaTimes /> : <RxHamburgerMenu />}
          </Navbar.Toggle> */}

          <Navbar.Collapse className="ms-3" id="basic-navbar-nav">
            <Nav className="nav-links">
              <Nav.Link
                className="custom"
                as={Link}
                to="/"
                onClick={handleLinkClick}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className="custom"
                as={Link}
                to="/market-news"
                onClick={handleLinkClick}
              >
                Market News
              </Nav.Link>
              <NavDropdown
                className="nav-dropdown-custom"
                title="Research"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="market-analysis"
                  onClick={handleLinkClick}
                >
                  Market Analysis
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="market-reports"
                  onClick={handleLinkClick}
                >
                  Market Reports
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="articles"
                  onClick={handleLinkClick}
                >
                  Articles
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to="analytics"
                  onClick={handleLinkClick}
                >
                  Analytics
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown className="nav-dropdown-custom" title="Top 100">
                <NavDropdown.Item
                  as={Link}
                  to="top100"
                  onClick={handleLinkClick}
                >
                  Top 100 Cryptocurrency
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/top-100-exchange"
                  onClick={handleLinkClick}
                >
                  Top 100 Exchange
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" onClick={handleLinkClick}>
                  Top 100 People
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" onClick={handleLinkClick}>
                  Top 100 Businesses
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/top-100-influncer"
                  onClick={handleLinkClick}
                >
                  Top 100 Influencer
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className="custom" as={Link} to="#link3">
                Free Crypto
              </Nav.Link>

              <NavDropdown
                className="nav-dropdown-custom"
                title="Crypto Projects"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/newListings"
                  onClick={handleLinkClick}
                >
                  New Listings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/upcoming-projects"
                  onClick={handleLinkClick}
                >
                  Upcoming Projects
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                className="custom"
                as={Link}
                to="#"
                onClick={handleLinkClick}
              >
                Events
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </Container>
  );
};


export default LowerNavbar;