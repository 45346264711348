import React from "react";
import { Container } from "react-bootstrap";


const Hero=({styles})=>{
    return(
      
        <Container fluid className={styles.backgroundVideoContainer}>
        <div className={styles.px0} >
          <video
            id="background-video"
            autoPlay
            loop
            muted
            poster="https://s3.amazonaws.com/indexbyte.bucket/test/bgmov.jpg"
            className={styles.backgroundVideo}
          >
            <source src="https://s3.amazonaws.com/indexbyte.bucket/test/bg.mov" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
        
         <header className={styles.header}>
         <div className="container px-5">
           <div className="row align-items-center">
             <div className="col-xxl-12">
               {/* Header text content */}
               <div className="text-center">
                 <div className={`badge bg-gradient-primary-to-secondary text-white mb-2 ${styles.badge}`}>
                   <div className="text-uppercase">NEWS · ANALYSIS · PREDICTION</div>
                 </div>
                 <div className="fs-3 fw-light text-white">I can help your money to</div>
                 <h1 className="display-3 fw-bolder mb-5">
                   <span className={`text-gradient d-inline ${styles.textGradient}`}>grow fast.. superfast...</span>
                 </h1>
                 {/* <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                   <a className="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="resume.html">SUBSCRIBE</a>
                  
                 </div> 
                 <div className="fs-5 fw-light text-white">Subscribe for US $9.00 pm</div>
                 */}
               </div>
             </div>
           </div>
         </div>
       </header>
       </Container>
    );
    }

    export default Hero;